import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { initFlowbite } from 'flowbite';
import Checkout from '../PaymentGateway/Checkout';
import axios from 'axios';
import { baseURL } from '@/utility/api';
import { MainContext } from '@/context/MainContext';

export default function CoursePayment() {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const [details, setDetails] = useState(null)
  const {user} = useContext(MainContext);
  const searchParams = new URLSearchParams(queryString);
  const {id} = useParams();
  const isGift = searchParams.get('isGift');
  const [info,setInfo] = useState({
    id : id, 
    name : user?.username,
    email : "",
    phone_number : "",
    type : "course",
    is_gift : isGift === "true" ? true : false

  })
  

  
  useEffect(() => {
    initFlowbite()
    async function fetchData(){
      const response = await axios.get(`${baseURL}/courses/get-course/${id}`);
      setDetails(response.data);
    }
    fetchData();
  }, [])

  return (
    <>
    <div className="w-full max-w-md mx-auto bg-secondary-300 text-gray-100 rounded-lg shadow-md overflow-hidden pb-20 md:mt-20 mt-[3.2rem] ">
      <div className="flex items-center space-x-4 p-4 border-b border-gray-700 ">
        <button className="text-gray-400 hover:text-gray-200" onClick={()=>navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <div className="flex items-center space-x-2">
          <div className="w-10 h-10 rounded-full bg-gray-600">
            <img src="/charchit_author.jpg" alt="" className="w-full h-full object-cover rounded-full"/>
          </div>
          <span className="font-semibold">{details?.author}</span>
      </div>

      </div>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-2">{details?.title}</h1>
        <p className="text-sm text-gray-400 mb-4">{details?.type}</p>
        <form>
          <div className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-300">{isGift === "true" ? "Friend's Name" :"Name"}</label>
              <input 
                type="text" 
                id="name" 
                name="name" 
                placeholder={isGift === "true" ? "Enter your friend's name" : "Enter your name"}
                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-gray-100 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500" 
                value={info?.name}
                onChange={(e)=>setInfo({...info,name:e.target.value})}
              />
            </div>
            <div className=''>
              
              <div className="flex justify-between">
                <div id="tooltip-animation" role="tooltip" class="absolute z-10 invisible  px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 flex">
                Got your email ID right? 
                <br></br>If not, your resources might play hide and seek later!
    <div class="tooltip-arrow" data-popper-arrow></div>
</div>
                  <span className="flex items-center">
                  <div  className="block text-sm font-medium text-gray-300">{isGift === "true" ? "Friend's Email ID" :"Email ID"}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" data-tooltip-target="tooltip-animation" className="h-4 w-4 ml-1 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                 
                </div>
              <input 
                type="email" 
                id="email" 
                name="email" 
                placeholder={isGift === "true" ? "Enter your friend's email" : "Enter your email"}
                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-gray-100 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500" 
                onChange={(e)=>setInfo({...info,email:e.target.value})}
              />
              
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-300">{isGift === "true" ? "Friend's Phone Number" :"Phone Number"}</label>
              <div className="flex mt-1">
                <select className="block px-3 py-2 bg-gray-700 border border-gray-600 rounded-l-md shadow-sm text-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                  <option value="+91">🇮🇳 +91</option>
                </select>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder={isGift === "true" ? "Enter your friend's phone number" : "Enter your phone number"}
                  className="flex-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-r-md shadow-sm text-gray-100 placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  value={info.phone_number}
                  onChange={(e) => setInfo({ ...info, phone_number: e.target.value })}
                  onInput={(e) => {
                    // Remove non-numeric characters
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  maxLength="10" // Optional: Limit phone number to 10 digits
                />
              </div>
            </div>

            {/* <div className="flex items-center space-x-2">
              <input 
                type="checkbox" 
                id="booking" 
                name="booking" 
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 bg-gray-700 border-gray-600 rounded" 
              />
              <label htmlFor="booking" className="text-sm text-gray-300">Receive booking details on phone</label>
            </div> */}
            
            
            <div className="mt-6 bg-gray-700 rounded-md p-4">
              <h2 className="text-lg font-semibold mb-4">Order Summary</h2>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span>1 x {details?.title}</span>
                  <span>₹{details?.discounted_price}</span>
                </div>
                
                <div className="flex justify-between font-semibold pt-2 border-t border-gray-600">
                  <span>Total</span>
                  <span>₹{parseInt(details?.discounted_price) }</span>
                </div>
              </div>
              
            </div>
          </div>
        </form>
        <div className="mt-4 text-sm text-gray-400 flex flex-col justify-between items-center">
                <span className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                  </svg>
                  Payments are 100% secure & encrypted
                </span>
               
                <span>
                  <a href="/terms-of-service" className="text-blue-400 hover:underline">Terms</a> | <a href="/privacy-policy" className="text-blue-400 hover:underline">Privacy</a>
                </span>
              </div>
        <div className="mt-4 text-center text-sm text-gray-400">
          Powered by off-campus.in
        </div>
      </div>
      
      
    </div>
    <div className="fixed bottom-0 left-0 right-0  p-4 border-t border-gray-700 bg-secondary-300 max-w-md mx-auto">
    <div className="w-full   flex justify-between items-center">
      <button 
        type="button" 
        className="px-4 py-2 bg-gray-700 text-gray-200 rounded-md flex items-center hover:bg-gray-600 transition-colors"
      >
        ₹{parseInt(details?.discounted_price) }+
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
      
      <Checkout info={info} title={"Confirm and Pay"} />
    </div>
  </div>
    </>
  )
}