import React from 'react'
import { FaInstagram, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
  return (
   

<footer className="rounded-lg shadow m-4 mt-10 mb-20 md:mb-0"> {/* Added mb-20 */}
  <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
    <div className="flex items-center justify-between w-full">
      <ul className="flex flex-wrap items-center mb-6 md:text-sm text-[0.5rem] font-medium sm:mb-0 text-gray-400 w-full justify-center">
        <li>
          <a href="/privacy-policy" className="hover:underline me-4 md:me-6">Privacy Policy</a>
        </li>
        <li>
          <a href="/terms-of-service" className="hover:underline me-4 md:me-6">Terms and Conditions</a>
        </li>
        <li>
          <a href="/cancellation-and-refund-policy" className="hover:underline md:me-6 me-4">Cancellation Policy</a>
        </li>
      </ul>
    </div>
    <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8 flex w-full" />
    <div className="flex justify-between w-full">
      <div className="block text-sm text-gray-400">
        © 2025 <a href="/" className="hover:underline">Off-Campus</a>.
        <br className="md:hidden" /> All Rights Reserved.
      </div>
      <div style={{ display: "flex", gap: "1rem", fontSize: "24px" }}>
      <a href="mailto:support@off-campus.in" style={{ color: "#D44638" }} aria-label="Email">
          <FaEnvelope />
        </a>
        <a href="https://www.instagram.com/offcampus.in/?utm_source=ig_web_button_share_sheet" target="_blank" rel="noopener noreferrer" style={{ color: "#E1306C" }} aria-label="Instagram">
          <FaInstagram />
        </a>
        <a href="https://www.linkedin.com/company/off-campus-in/" target="_blank" rel="noopener noreferrer" style={{ color: "#0077B5" }} aria-label="LinkedIn">
          <FaLinkedin />
        </a>
        <a href="https://wa.me/919560699151" target="_blank" rel="noopener noreferrer" className="text-green-400" aria-label="WhatsApp">
          <IoLogoWhatsapp />
        </a>
      </div>
    </div>
  </div>
</footer>



  )
}

export default Footer