import React, { useState } from "react";
import {
  Clock,
  CalendarDays,
  BookOpen,
  Users,
  Medal,
  Handshake,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";

// About Program Component
const AboutProgram = () => {
  const highlights = [
    {
      icon: <Clock className="w-5 h-5 text-blue-400" />,
      title: "4-week intensive fellowship",
      description:
        "Immersive learning experience compressed into an efficient timeframe",
    },
    {
      icon: <CalendarDays className="w-5 h-5 text-indigo-400" />,
      title: "3 sessions per week",
      description:
        "2 hours each, perfectly balanced for deep learning without overwhelm",
    },
    {
      icon: <BookOpen className="w-5 h-5 text-purple-400" />,
      title: "20 Cases & 20 Guesstimates",
      description:
        "Comprehensive coverage of all essential consulting problem types",
    },
    {
      icon: <Users className="w-5 h-5 text-yellow-500" />,
      title: "Application-based selection",
      description: "Pay only if selected - first-come, first-serve basis",
    },
    {
      icon: <Medal className="w-5 h-5 text-amber-500" />,
      title: "Certificate of Fellowship",
      description:
        "Receive a certificate at the end of the fellowship – proof of your consulting skills!",
    },
    {
      icon: <Handshake className="w-5 h-5 text-teal-500" />,
      title: "Community & Buddy System",
      description:
        "Join a group of all students in the fellowship and get paired with a buddy for extra case practice and learning support!!",
    },
  ];

  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollLeft = () => {
    const container = document.getElementById("highlights-container");
    if (container) {
      container.scrollBy({ left: -280, behavior: "smooth" });
      setScrollPosition(container.scrollLeft - 280);
    }
  };

  const scrollRight = () => {
    const container = document.getElementById("highlights-container");
    if (container) {
      container.scrollBy({ left: 280, behavior: "smooth" });
      setScrollPosition(container.scrollLeft + 280);
    }
  };

  return (
    <div className="bg-black py-32 lg:py-16 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-center mb-10">
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-blue-500/10 border border-blue-500/30 backdrop-blur-sm">
            <div className="w-4 h-4 bg-blue-400 rounded-full animate-pulse" />
            <span className="text-sm font-semibold text-blue-400 tracking-wide uppercase">
              About The Fellowship
            </span>
          </div>
        </div>

        <div className="space-y-6">
          <h2 className="text-center text-3xl sm:text-4xl font-bold mb-8">
            <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
              Your Gateway to Consulting Success
            </span>
          </h2>

          <p className="text-lg text-gray-400 text-center max-w-3xl mx-auto mb-10">
            So, you want to break into consulting? We've got you! This 4-week
            fellowship is tailor-made for anyone who dreams of making it big in
            consulting. From cracking cases to nailing guesstimates, building
            the perfect resume, and mastering cold mailing – we cover it all.
          </p>

          {/* Card Section */}
          <div className="relative">
            {/* Desktop Grid View */}
            <div className="hidden md:grid md:grid-cols-2 md:gap-6">
              {highlights.map((highlight, index) => (
                <div
                  key={index}
                  className="group relative bg-black border border-blue-500/30 rounded-lg p-6
                            hover:border-blue-400 transition-all duration-300
                            hover:bg-gradient-to-br hover:from-blue-900/20 hover:to-indigo-900/20"
                >
                  <div
                    className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-indigo-500
                                rounded-lg blur opacity-0 group-hover:opacity-30
                                transition duration-300"
                  />

                  <div className="relative flex flex-col items-center text-center sm:flex-row sm:items-start sm:text-left gap-4">
                    <div className="p-3 rounded-lg bg-blue-500/10">
                      {highlight.icon}
                    </div>

                    <div className="flex-1">
                      <h3 className="text-lg font-bold mb-2 text-white group-hover:text-blue-300 transition-colors">
                        {highlight.title}
                      </h3>
                      <p className="text-gray-400 text-sm group-hover:text-gray-200 transition-colors">
                        {highlight.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Mobile Scroll View */}
            <div
              id="highlights-container"
              className="md:hidden flex overflow-x-auto pb-4 snap-x snap-mandatory scrollbar-hide"
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            >
              {highlights.map((highlight, index) => (
                <div
                  key={index}
                  className="group relative bg-black border border-blue-500/30 rounded-lg p-6
                            hover:border-blue-400 transition-all duration-300
                            hover:bg-gradient-to-br hover:from-blue-900/20 hover:to-indigo-900/20
                            flex-shrink-0 w-full min-w-[260px] max-w-xs snap-start
                            mr-4"
                >
                  <div
                    className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-indigo-500
                                rounded-lg blur opacity-0 group-hover:opacity-30
                                transition duration-300"
                  />

                  <div className="relative flex flex-col items-center text-center gap-4">
                    <div className="p-3 rounded-lg bg-blue-500/10">
                      {highlight.icon}
                    </div>

                    <div className="flex-1">
                      <h3 className="text-lg font-bold mb-2 text-white group-hover:text-blue-300 transition-colors">
                        {highlight.title}
                      </h3>
                      <p className="text-gray-400 text-sm group-hover:text-gray-200 transition-colors">
                        {highlight.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Scroll Controls for Mobile */}
          <div className="md:hidden flex justify-center gap-4 mb-4">
            <button
              onClick={scrollLeft}
              className="p-2 rounded-full bg-blue-500/10 border border-blue-500/30 text-blue-400"
              aria-label="Scroll left"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <button
              onClick={scrollRight}
              className="p-2 rounded-full bg-blue-500/10 border border-blue-500/30 text-blue-400"
              aria-label="Scroll right"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>

          {/* Price Card */}
          <div
            className="mt-10 bg-gradient-to-r from-blue-500/20 to-indigo-500/20 rounded-xl p-6
                      backdrop-blur-xl border border-blue-500/20
                      hover:border-blue-500/50 transition-all duration-300
                      hover:shadow-lg hover:shadow-blue-500/20
                      text-center"
          >
            <h3
              className="font-semibold text-xl text-blue-400
                         hover:text-blue-300 transition-colors duration-300 mb-2"
            >
              Price: ₹1000
            </h3>
            <p
              className="text-gray-400 hover:text-gray-300
                        transition-colors duration-300 text-lg"
            >
              Only if selected - no heartbreak charges!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutProgram;
