import React, { useEffect, useState } from 'react'
import Cards from './Cards'
import NewCards from './NewCards'

const ProductsSection = ({prices}) => {
  const [isVisible, setIsVisible] = useState(false);
  const data = [
    {
      title: "Resume Writing",
      rating: 4.9,
      discounted_price: prices?.resume_maker,
      description:
        "Tired of cookie-cutter resumes? Our Resume Writing Service is here to craft a personalized, ATS-friendly resume that showcases your unique strengths and achievements. Hop on a quick call with us, share your journey, and let us do the magic!",
        subfunction: "Tailored Perfection",
        link:"/resume-maker"
    },
    {
      title: "Resume Template",
      rating: 4.9,
      discounted_price: prices?.resume_template,
      description:
        "Tired of your resume looking like it’s applying for a 2008 opportunity? This resource includes editable resume template designed to help you shine brighter than your Wi-Fi signal. Bonus templates included for all your one-pager needs!",
        subfunction: "Editable Template",
        link : "/bookcall/pay/101?type=product"
    },
    {
      title: "Resume Review",
      rating: 4.8,
      discounted_price: prices?.resume_review,
      description:
        "Think your resume is almost there but not quite? Send it our way with your queries, and we’ll polish it to perfection. Our experts will review it in 24 hours and ensure your resume is recruiter-ready!",
        subfunction: "Feedback that Clicks",
        link:"/resume-review"
    },
    {
      title: "Mailing Templates",
      rating: 4.7,
      discounted_price: prices?.mail_template,
      description:
        "Crafting the perfect email feels like rocket science? This resource includes cold mailing and follow-up templates engineered to grab attention. Slide into inboxes like a pro and fetch that dream opportunity!",
        subfunction: "Cold & Follow-Up Emails",
        link:"/bookcall/pay/102?type=product"
    },
    
  ]
  
    useEffect(() => {
      setIsVisible(true);
    }, []);
  return (
    <div className='container flex items-center justify-center'>
    <div className={` mt-10  gap-10 grid lg:grid-cols-3  transition-all duration-1000 ${
                isVisible
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-10"
              }`}>
      
      {data.map((product) => (
        <NewCards key={product.id} product={product} />
      ))}
        
    </div>
    </div>
  )
}

export default ProductsSection