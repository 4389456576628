import React, { useState, useEffect } from "react";
import {
  Rocket,
  Briefcase,
  Calendar,
  Clock,
  BookOpen,
  FileText,
  Mail,
  User,
  Users,
  Award,
  Target,
  TrendingUp,
  FileCheck,
  Presentation,
  CalendarDays,
  HelpCircle,
  ChevronDown,
  ChevronUp,
  Phone,
  Bookmark,
  PenLine,
} from "lucide-react";

// Application Form Component
const ApplicationForm = ({ price, canApply, seats_left }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    college: "",
    year: "",
    why: "",
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [focusedField, setFocusedField] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  const handleNextStep = () => setCurrentStep(2);
  const handlePrevStep = () => setCurrentStep(1);

  return (
    <div className="bg-gradient-to-br from-gray-900 to-black backdrop-blur-lg border border-gray-700 rounded-xl p-6 shadow-xl hover:shadow-indigo-500/5 transition-all duration-300">
      <div className="mb-6 text-center">
        <h3 className="text-2xl font-bold mb-2 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
          Apply for the Program
        </h3>

        {/* Form Progress Indicator */}
        <div className="flex items-center justify-center gap-2 mb-4 mt-3">
          <div
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              currentStep === 1
                ? "bg-indigo-500 scale-110"
                : "bg-gray-600 hover:bg-gray-500 cursor-pointer"
            }`}
            onClick={() => setCurrentStep(1)}
          />
          <div className="w-10 h-0.5 bg-gray-700" />
          <div
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              currentStep === 2
                ? "bg-indigo-500 scale-110"
                : "bg-gray-600 hover:bg-gray-500 cursor-pointer"
            }`}
            onClick={() => setCurrentStep(2)}
          />
        </div>

        {seats_left && (
          <div className="bg-gray-800/50 rounded-lg p-3 border border-gray-700 mb-4">
            <p className="text-gray-300 mb-2 flex justify-center items-center gap-2">
              <Users className="w-4 h-4 text-indigo-400" />
              <span className="font-semibold text-white">
                {seats_left}
              </span>{" "}
              seats left
            </p>
            <div className="w-full h-2 bg-gray-900 rounded-full overflow-hidden">
              <div
                className="h-full bg-gradient-to-r from-purple-500 to-indigo-500 rounded-full transition-all duration-1000 ease-in-out"
                style={{ width: `${100 - (seats_left / 30) * 100}%` }}
              />
            </div>
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit} className="space-y-5">
        {currentStep === 1 ? (
          <div className="space-y-5">
            <h4 className="text-gray-300 font-medium text-sm uppercase tracking-wide mb-3 flex items-center">
              <User className="w-4 h-4 mr-2" /> Personal Information
            </h4>

            <div className="relative group">
              <div
                className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none transition-colors duration-300 ${
                  focusedField === "name" ? "text-indigo-400" : "text-gray-500"
                }`}
              >
                <User className="w-5 h-5" />
              </div>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onFocus={() => setFocusedField("name")}
                onBlur={() => setFocusedField(null)}
                placeholder="Full Name"
                className="w-full pl-10 pr-4 py-3.5 bg-gray-800 border border-gray-700 rounded-lg 
                             focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500/30 text-white
                             transition-all duration-300 hover:border-gray-600"
                required
              />
            </div>

            <div className="relative group">
              <div
                className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none transition-colors duration-300 ${
                  focusedField === "email" ? "text-indigo-400" : "text-gray-500"
                }`}
              >
                <Mail className="w-5 h-5" />
              </div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onFocus={() => setFocusedField("email")}
                onBlur={() => setFocusedField(null)}
                placeholder="Email Address"
                className="w-full pl-10 pr-4 py-3.5 bg-gray-800 border border-gray-700 rounded-lg 
                             focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500/30 text-white
                             transition-all duration-300 hover:border-gray-600"
                required
              />
            </div>

            <div className="relative group">
              <div
                className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none transition-colors duration-300 ${
                  focusedField === "phone" ? "text-indigo-400" : "text-gray-500"
                }`}
              >
                <Phone className="w-5 h-5" />
              </div>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                onFocus={() => setFocusedField("phone")}
                onBlur={() => setFocusedField(null)}
                placeholder="Phone Number"
                className="w-full pl-10 pr-4 py-3.5 bg-gray-800 border border-gray-700 rounded-lg 
                             focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500/30 text-white
                             transition-all duration-300 hover:border-gray-600"
                required
              />
            </div>

            <button
              type="button"
              onClick={handleNextStep}
              className="w-full mt-4 py-3.5 rounded-lg font-bold text-lg shadow-lg 
                          bg-gradient-to-r from-purple-600 to-indigo-600 text-white
                          transition-all duration-300 hover:from-purple-700 hover:to-indigo-700
                          active:scale-[0.98]"
            >
              Continue
            </button>
          </div>
        ) : (
          <div className="space-y-5">
            <h4 className="text-gray-300 font-medium text-sm uppercase tracking-wide mb-3 flex items-center">
              <BookOpen className="w-4 h-4 mr-2" /> Education & Motivation
            </h4>

            <div className="relative group">
              <div
                className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none transition-colors duration-300 ${
                  focusedField === "college"
                    ? "text-indigo-400"
                    : "text-gray-500"
                }`}
              >
                <Bookmark className="w-5 h-5" />
              </div>
              <input
                type="text"
                name="college"
                value={formData.college}
                onChange={handleChange}
                onFocus={() => setFocusedField("college")}
                onBlur={() => setFocusedField(null)}
                placeholder="College/University"
                className="w-full pl-10 pr-4 py-3.5 bg-gray-800 border border-gray-700 rounded-lg 
                             focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500/30 text-white
                             transition-all duration-300 hover:border-gray-600"
                required
              />
            </div>

            <div className="relative group">
              <div
                className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none transition-colors duration-300 ${
                  focusedField === "year" ? "text-indigo-400" : "text-gray-500"
                }`}
              >
                <Calendar className="w-5 h-5" />
              </div>
              <select
                name="year"
                value={formData.year}
                onChange={handleChange}
                onFocus={() => setFocusedField("year")}
                onBlur={() => setFocusedField(null)}
                className="w-full pl-10 pr-10 py-3.5 bg-gray-800 border border-gray-700 rounded-lg 
                             focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500/30 text-white
                             transition-all duration-300 hover:border-gray-600 appearance-none"
                required
              >
                <option value="" disabled>
                  Select Year
                </option>
                <option value="1">1st Year</option>
                <option value="2">2nd Year</option>
                <option value="3">3rd Year</option>
                <option value="4">4th Year</option>
                <option value="5">5th Year</option>
                <option value="graduate">Graduate</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">
                <ChevronDown className="w-5 h-5" />
              </div>
            </div>

            <div className="relative group">
              <div
                className={`absolute top-3 left-0 flex items-start pl-3 pointer-events-none transition-colors duration-300 ${
                  focusedField === "why" ? "text-indigo-400" : "text-gray-500"
                }`}
              >
                <PenLine className="w-5 h-5" />
              </div>
              <textarea
                name="why"
                value={formData.why}
                onChange={handleChange}
                onFocus={() => setFocusedField("why")}
                onBlur={() => setFocusedField(null)}
                placeholder="Why do you want to join this program? (Briefly)"
                rows="4"
                className="w-full pl-10 pr-4 py-3.5 bg-gray-800 border border-gray-700 rounded-lg 
                            focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500/30 text-white
                            transition-all duration-300 hover:border-gray-600"
                required
              ></textarea>
            </div>

            <div className="flex gap-4 mt-6">
              <button
                type="button"
                onClick={handlePrevStep}
                className="w-1/3 py-3 rounded-lg font-medium text-base
                            bg-gray-800 text-gray-300 border border-gray-700
                            transition-all duration-300 hover:bg-gray-700 
                            active:scale-[0.98]"
              >
                Back
              </button>

              <button
                type="submit"
                disabled={!canApply}
                className={`w-2/3 py-3.5 rounded-lg font-bold text-lg shadow-lg 
                              transition-all duration-300 active:scale-[0.98]
                              ${
                                canApply
                                  ? "bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:from-purple-700 hover:to-indigo-700"
                                  : "bg-gray-700 text-gray-400 cursor-not-allowed"
                              }`}
              >
                Submit Application
              </button>
            </div>
          </div>
        )}

        <div className="bg-gray-800/60 rounded-lg p-4 border border-gray-700 mt-6">
          <div className="flex items-start">
            <div className="mt-1 mr-3 flex-shrink-0">
              <div className="w-6 h-6 bg-indigo-400/20 rounded-full flex items-center justify-center">
                <svg
                  className="w-4 h-4 text-indigo-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
            <div>
              <p className="text-sm text-white font-medium">
                Pay only if selected - ₹{price || 1000}
              </p>
              <p className="text-xs text-gray-400 mt-1">
                Selected candidates will receive an email with payment
                instructions
              </p>
            </div>
          </div>
        </div>

        <p className="text-center text-xs text-gray-500 mt-6">
          By applying, you agree to our{" "}
          <span className="text-indigo-400 hover:text-indigo-300 cursor-pointer transition-colors">
            Terms of Service
          </span>{" "}
          and{" "}
          <span className="text-indigo-400 hover:text-indigo-300 cursor-pointer transition-colors">
            Privacy Policy
          </span>
        </p>
      </form>
    </div>
  );
};

export default ApplicationForm;
