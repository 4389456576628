import React from "react";
import {
  Star,
  Clock,
  Building2,
  Trophy,
  ArrowUpRight,
  Calendar,
  Verified,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

const CardComponent = ({ session }) => {
    const navigate = useNavigate();
  // Dummy data
//   const session = {

//     name: "Sarah Johnson",
//     image: "/api/placeholder/150/150",
//     rating: 4.8,
//     reviews: 124,
//     position: "Lead Product Designer",
//     status: "Available",
//     nextSlot: "Today, 3 PM",
//     company: {
//       current: "Google",
//       experience: "8+ years",
//     },
//     badges: ["Top 1%", "Featured", "Enterprise"],
//     expertise: [
//       "Product Strategy",
//       "Design Systems",
//       "User Research",
//       "Team Leadership",
//     ],
//     metrics: [
//       { label: "Sessions", value: "200+" },
//       { label: "Hours", value: "500+" },
//       { label: "Projects", value: "45" },
//     ],

// }




return (
    <div className="w-full max-w-xl mx-auto mt-8">
        <div className="relative group">
            {/* Background card effects */}
            <div className="absolute -right-2 -bottom-2 w-full h-full bg-violet-600/20 rounded-2xl transition-all duration-300 group-hover:-translate-x-2 group-hover:translate-y-2" />
            <div className="absolute -left-2 -top-2 w-full h-full bg-blue-600/20 rounded-2xl transition-all duration-300 group-hover:translate-x-2 group-hover:-translate-y-2" />

            {/* Main card */}
            <div className="relative bg-gray-900 rounded-2xl p-6 border border-gray-800">
                {/* Top status banner */}
                {/* <div className="absolute -top-3 left-1/2 -translate-x-1/2 px-4 py-1 bg-gradient-to-r from-green-500 to-emerald-500 rounded-full flex items-center gap-2">
                    <div className="w-2 h-2 bg-white rounded-full animate-pulse" />
                    <span className="text-xs font-medium text-white">
                        Available Now
                    </span>
                </div> */}

                {/* Header section */}
                <div className="flex items-start gap-6 mb-6">
                    {/* Profile image with status ring */}
                    <div className="relative">
                        <div className="absolute inset-0 bg-gradient-to-tr from-violet-500 via-blue-500 to-violet-500 rounded-2xl animate-gradient" />
                        <img
                            src={session.image || '/default-image.jpg'}
                            alt={session.name}
                            className="relative w-24 h-24 rounded-2xl object-cover border-2 border-gray-800"
                        />
                        {/* <div className="absolute -top-2 -right-2 bg-gradient-to-r from-yellow-500 to-orange-500 p-1.5 rounded-lg">
                            <Trophy className="w-3 h-3 text-white" />
                        </div> */}
                    </div>

                    {/* Name and company info */}
                    <div className="flex-1">
                        <div className="flex justify-between items-start mb-2">
                            <div>
                                <h3 className="text-xl font-bold text-white flex items-center gap-2">
                                    {session.name}
                                    {/* <Verified className="w-5 h-5 text-blue-500" /> */}
                                </h3>
                                <p className="text-violet-400 font-medium text-sm">
                                    {session.position}
                                </p>
                            </div>
                            {/* <div className="flex items-center gap-1 bg-yellow-500/10 px-2 py-1 rounded-lg">
                                <Star className="w-4 h-4 text-yellow-500 fill-current" />
                                <span className="text-yellow-500 font-bold">
                                    {session.rating}
                                </span>
                            </div> */}
                        </div>

                        {/* <div className="flex items-center gap-4 text-sm">
                            <div className="flex items-center gap-1.5 text-gray-400">
                                <Building2 className="w-4 h-4" />
                                <span>{session.company.current}</span>
                            </div>
                            <div className="flex items-center gap-1.5 text-gray-400">
                                <Clock className="w-4 h-4" />
                                <span>{session.company.experience}</span>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* Badges */}
                <div className="flex gap-2 mb-6 flex-wrap">
                    {session.badges.map((badge) => (
                        <span
                            key={badge}
                            className="px-3 py-1 rounded-lg text-xs font-medium bg-gradient-to-r from-violet-500/10 to-blue-500/10 text-violet-400 border border-violet-500/20"
                        >
                            {badge}
                        </span>
                    ))}
                </div>

                {/* Metrics */}
                <div className="grid grid-cols-3 gap-4 mb-6">
                    {session.metrics.map((metric) => (
                        <div
                            key={metric.label}
                            className="bg-gray-800/50 rounded-xl p-3 text-center"
                        >
                            <div className= "flex items-center justify-center gap-2">{metric.label==="Rating" &&  <Star className="w-4 h-4 text-yellow-500 fill-current" />}
                            <div className="text-lg font-bold text-white w-fit">
                                {metric.value}
                            </div>
                            </div>
                            <div className="text-xs text-gray-400">{metric.label}</div>
                        </div>
                    ))}
                </div>

                {/* Expertise */}
                {/* <div className="mb-6">
                    <div className="text-sm font-medium text-gray-400 mb-2">
                        Expertise
                    </div>
                    <div className="flex flex-wrap gap-2">
                        {session.expertise.map((skill) => (
                            <span
                                key={skill}
                                className="px-3 py-1 rounded-full text-xs font-medium bg-blue-500/10 text-blue-400 border border-blue-500/20"
                            >
                                {skill}
                            </span>
                        ))}
                    </div>
                </div> */}

                {/* Action buttons */}
                <div className="flex gap-3">
                    <button
                        className="flex-1 bg-gradient-to-r from-violet-600 to-blue-600 text-white px-4 py-3 rounded-xl font-medium 
                                             hover:from-violet-500 hover:to-blue-500 transition-all duration-300 flex items-center justify-center gap-2 group"
                        onClick={() => {navigate(`/bookcall/${session.id}`)}}
                    >
                        Book Call
                        <ArrowUpRight className="w-4 h-4 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
                    </button>
                    {/* <button className="px-4 py-3 rounded-xl font-medium border border-gray-800 hover:bg-gray-800 transition-colors duration-300 flex items-center gap-2">
                        <Calendar className="w-4 h-4 text-violet-400" />
                        <span className="text-violet-400">View Schedule</span>
                    </button> */}
                </div>

                {/* Next available slot indicator */}
                {/* <div className="absolute -bottom-6 left-1/2 -translate-x-1/2 px-4 py-1 bg-gray-800 rounded-full flex items-center gap-2 border border-gray-700">
                    <Clock className="w-3 h-3 text-violet-400" />
                    <span className="text-xs font-medium text-gray-300">
                        Next available: {session.nextSlot}
                    </span>
                </div> */}
            </div>
        </div>
    </div>
);


};


export default CardComponent;