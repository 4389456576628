import React, { useState, useEffect } from "react";
import {
  Rocket,
  Briefcase,
  Calendar,
  Clock,
  BookOpen,
  FileText,
  Mail,
  User,
  Users,
  Award,
  Target,
  TrendingUp,
  FileCheck,
  Presentation,
  CalendarDays,
  HelpCircle,
  ChevronDown,
  ChevronUp,
  Phone,
  Bookmark,
  PenLine,
  Library,
  BookOpenIcon,
  BookIcon,
} from "lucide-react";

// Curriculum Component
const Curriculum = () => {
  const weeks = [
    {
      week: "Week 1",
      title: "Market Entry Cases",
      icon: <Target className="w-5 h-5 text-blue-400" />,
      description:
        "Learn how to tackle market entry cases with practical examples and structured approaches.",
    },
    {
      week: "Week 2",
      title: "Profitability Cases",
      icon: <TrendingUp className="w-5 h-5 text-indigo-400" />,
      description:
        "Master the art of analyzing and solving profitability problems using key frameworks.",
    },
    {
      week: "Week 3",
      title: "Pricing & Growth",
      icon: <FileCheck className="w-5 h-5 text-purple-400" />,
      description: "Learn strategies for pricing products and planning growth.",
    },
    {
      week: "Week 4",
      title: "Miscellaneous",
      icon: <Presentation className="w-5 h-5 text-emerald-400" />,
      description:
        "Covering everything from M&A to operational efficiency and more.",
    },
    {
      // week: "Week 4",
      title: "Resume Building Session",
      icon: <BookOpenIcon className="w-5 h-5 text-amber-400" />,
      description:
        " Learn to craft a consulting-ready resume that stands out from the competition and showcases your unique value proposition",
    },
    {
      // week: "Week 4",
      title: "Cold Mailing & Referrals",
      icon: <BookOpenIcon className="w-5 h-5 text-amber-400" />,
      description:
        "Master effective networking strategies to get your foot in the door at top consulting firms and secure valuable referrals.",
    },
  ];

  return (
    <div className="bg-black py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-center mb-10">
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-blue-500/10 border border-blue-500/30 backdrop-blur-sm">
            <CalendarDays className="w-4 h-4 text-blue-400" />
            <span className="text-sm font-semibold text-blue-400 tracking-wide uppercase">
              Fellowship Curriculum
            </span>
          </div>
        </div>

        <div className="space-y-6">
          <h2 className="text-center text-3xl sm:text-4xl font-bold mb-8">
            <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
              4-Week Journey to Consulting Excellence
            </span>
          </h2>

          {/* Timeline */}
          <div
            className="relative space-y-8 py-2 before:absolute before:inset-0 before:left-[15px] md:before:left-1/2 
                            before:ml-0.5 md:before:ml-0 before:w-0.5 before:bg-gradient-to-b before:from-blue-500 
                            before:via-indigo-500 before:to-purple-500 before:opacity-20"
          >
            {weeks.map((week, index) => (
              <div
                key={index}
                className={`relative flex items-center ${
                  index % 2 === 0 ? "md:flex-row-reverse" : ""
                }`}
              >
                {/* Timeline dot */}
                <div
                  className="absolute left-0 md:left-1/2 transform -translate-x-1/2 w-8 h-8 
                                  rounded-full bg-black border-2 border-blue-400 z-10
                                  flex items-center justify-center"
                >
                  {week.icon}
                </div>

                {/* Content card */}
                <div
                  className={`ml-12 md:ml-0 ${
                    index % 2 === 0
                      ? "md:mr-12 md:pr-16 md:text-right"
                      : "md:ml-12 md:pl-16"
                  } 
                               w-full md:w-2/5 group relative bg-black border border-blue-500/30 rounded-lg p-6 
                               hover:border-blue-400 transition-all duration-300 
                               hover:bg-gradient-to-br hover:from-blue-900/20 hover:to-indigo-900/20`}
                >
                  <div
                    className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-indigo-500 
                                    rounded-lg blur opacity-0 group-hover:opacity-30 
                                    transition duration-300"
                  />

                  <div className="relative">
                    <div className="font-bold text-blue-400 mb-1">
                      {week?.week}
                    </div>
                    <h3 className="text-lg font-bold mb-2 text-white group-hover:text-blue-300 transition-colors">
                      {week.title}
                    </h3>
                    <p className="text-gray-400  text-sm group-hover:text-gray-200 transition-colors">
                      {week.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Additional Sessions
          <div className="mt-12 grid md:grid-cols-2 gap-6">
            <div
              className="group relative bg-black border border-blue-500/30 rounded-lg p-6 
                           hover:border-blue-400 transition-all duration-300 
                           hover:bg-gradient-to-br hover:from-blue-900/20 hover:to-indigo-900/20"
            >
              <div
                className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-indigo-500 
                                rounded-lg blur opacity-0 group-hover:opacity-30 
                                transition duration-300"
              />

              <div className="relative flex items-start gap-4">
                <div className="p-3 rounded-lg bg-blue-500/10">
                  <FileText className="w-5 h-5 text-blue-400" />
                </div>

                <div className="flex-1">
                  <h3 className="text-lg font-bold mb-2 text-white group-hover:text-blue-300 transition-colors">
                    Resume Building Session
                  </h3>
                  <p className="text-gray-400 text-sm group-hover:text-gray-200 transition-colors">
                    Learn to craft a consulting-ready resume that stands out
                    from the competition and showcases your unique value
                    proposition.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="group relative bg-black border border-blue-500/30 rounded-lg p-6 
                           hover:border-blue-400 transition-all duration-300 
                           hover:bg-gradient-to-br hover:from-blue-900/20 hover:to-indigo-900/20"
            >
              <div
                className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-indigo-500 
                                rounded-lg blur opacity-0 group-hover:opacity-30 
                                transition duration-300"
              />

              <div className="relative flex items-start gap-4">
                <div className="p-3 rounded-lg bg-blue-500/10">
                  <Mail className="w-5 h-5 text-blue-400" />
                </div>

                <div className="flex-1">
                  <h3 className="text-lg font-bold mb-2 text-white group-hover:text-blue-300 transition-colors">
                    Cold Mailing & Referrals
                  </h3>
                  <p className="text-gray-400 text-sm group-hover:text-gray-200 transition-colors">
                    Master effective networking strategies to get your foot in
                    the door at top consulting firms and secure valuable
                    referrals.
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          {/* Apply Now Button */}
          <div className="mt-12 text-center">
            <p className="mt-4 text-gray-400 mb-4">
              Ready to be a consulting beast?
            </p>
            <button
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSfPZbzISgsQFZ_faTaEGyQM0jaGTWPGEKcaEOdoNZe_qwLBoA/viewform",
                  "_blank"
                )
              }
              className="px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-800 
                          text-white font-bold rounded-lg shadow-lg 
                          hover:shadow-blue-600/20 transition-all duration-300
                          hover:scale-105"
            >
              Apply Now
            </button>
            {/* <p className="mt-4 text-gray-400">
              Ready to be a consulting beast?
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Curriculum;
