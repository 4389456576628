import React, { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight, ChevronDown } from 'lucide-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { current } from 'tailwindcss/colors'
import { baseURL, url } from '@/utility/api'
import axios from 'axios'
import { formatDate } from '@/utility/dateFormatter'

import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function DateTimePicker({title,buttonText,sessionDate,isReschedule,rescheduleFor=0,id,dynamicPricing,isRebook=false}) {
  const [dates,setDates] = useState([])
  const [time,setTime] = useState(["10"])
  const [selectedDate, setSelectedDate] = useState(" ")
  const [selectedIsoDate, setSelectedIsoDate] = useState("")
  const [loading,setLoading] = useState(false)
  const [selectedTime, setSelectedTime] = useState(" ")
  const [visibleStartIndex, setVisibleStartIndex] = useState(0) // To track the start index for visible dates
  const [selectedPrice,setSelectedPrice] = useState(0);
  const [friend,setFriend] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  

  const handleNavigation = () => {

    if(selectedDate === " "){
      toast.error('Please select a date and time');
      return;
    }
    const currentPath = location.pathname;
      // Get the current URL pat
      let redirectUrl = `${url}/bookcall/pay/${id}?type=session&date=${formatDate(selectedDate,selectedTime,selectedIsoDate)}&isReschedule=${isReschedule}&rescheduleFor=${rescheduleFor}&isFriend=${friend}&isRebook=${isRebook}`

      redirectUrl += isReschedule || !dynamicPricing ? `` : `&price=${selectedPrice}`
    
      window.location.href = redirectUrl;
    
  }
  
  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const response = await axios.post(`${baseURL}/session/get-dates`,{id:id,
        sessionDate
      })
      setDates(response.data.dates)
      setTime(response.data.dates[0].timings)
      setSelectedTime(response.data.time[0])
      setSelectedDate(response.data.dates[0].date)
      setSelectedIsoDate(response.data.dates[0].isoDate)
      setLoading(false)
      setSelectedPrice(response.data.dates[0].price)
    }
    fetchData()
  }, [])

  useEffect(() => {
    setSelectedTime(time[0])
  }, [time])

  

  

  const visibleDates = dates.slice(visibleStartIndex, visibleStartIndex + 4)

  const handleNextWeek = () => {
    if (visibleStartIndex + 4 < dates.length) {
      setVisibleStartIndex(visibleStartIndex + 1)
    }
  }

  const handlePreviousWeek = () => {
    if (visibleStartIndex > 0) {
      setVisibleStartIndex(visibleStartIndex - 1)
    }
  }
  if(loading){
    return <div>Loading...</div>
  }

  return (
    <div className="w-full   max-w-[calc(100vw-2rem)] sm:max-w-[calc(100vw-4rem)] lg:max-w-md  mx-auto bg-[#F8EDED] rounded-lg shadow-lg overflow-hidden text-black font-[700]">
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>


        <div className=" items-center justify-between mb-6 lg:flex hidden ">
          <button
            className=" hover:text-gray-800"
            aria-label="Previous week"
            onClick={handlePreviousWeek}
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <div className="flex space-x-2 ">
            {visibleDates.map(({ day, date,timings,isoDate,price }) => (
              <button
                key={date}
                onClick={() => {
                  setSelectedDate(date);
                  setTime(timings)
                  setSelectedIsoDate(isoDate);
                  setSelectedPrice(price)

                }}
                className={`px-4 py-2 rounded-lg text-sm ${
                  selectedDate === date
                    ? 'bg-black text-white '
                    : ' hover:bg-gray-100'
                }`}
              >
                <div className="font-medium">{day}</div>
                <div>{date}</div>
                {!isReschedule && dynamicPricing &&<div className='mt-4'>₹{price}</div>}
              </button>
            ))}
          </div>
          <button
            className=" hover:text-gray-800"
            aria-label="Next week"
            onClick={handleNextWeek}
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>



        <div className=" items-center w-full justify-between mb-6 lg:hidden flex overflow-y-auto">
         
          <div className="flex space-x-2 ">
            {dates.map(({day,date,timings,isoDate,price},index) => (
              <button
                key={index}
                onClick={() => {
                  setSelectedDate(date);
                  setSelectedIsoDate(isoDate)
                  setTime(timings)
                  setSelectedPrice(price)
                  }}
                className={`px-4 py-2 rounded-lg text-sm ${
                  selectedDate === date
                    ? 'bg-black text-white '
                    : ' hover:bg-gray-100'
                }`}
              >
                <div className="font-medium">{day}</div>
                <div className='whitespace-nowrap'>{date}</div>
                {!isReschedule && dynamicPricing &&<div className='mt-4'>₹{price}</div>}
              </button>
            ))}
          </div>
          
        </div>
        <div className="mb-6">
          <label htmlFor="time" className="block text-sm font-medium  mb-2">
            Select time of day
          </label>
          <div className='flex gap-4 overflow-x-auto'>
          {time.map((value,index) => (
            <button
              key={index}
              onClick={() => {setSelectedTime(value);}}
              className={`px-3 py-2 rounded-lg text-sm ${
                selectedTime === value
                  ? 'bg-black text-white font-semibold'
                  : ' hover:bg-gray-100'
              }`}
            >
              {value}
            </button>
          ))}
          </div>

          {(id == 101 || id== 201) &&
          <div className="flex items-center gap-2 mt-4">
            <input
              type="checkbox"
              id="friendCheckbox"
              checked={friend}
              onChange={() => setFriend(!friend)}
              className="w-5 h-5 accent-blue-500 rounded cursor-pointer"
            />

            <label htmlFor="friendCheckbox" className=" cursor-pointer">
              Bring your friend
            </label>
          </div>
          }

        </div>
       
        <button
          onClick={handleNavigation}
          className="w-full flex justify-center bg-black text-white py-3 rounded-lg font-semibold hover:bg-gray-800 transition-colors"
         
        >
          {buttonText}
        </button>
      </div>
      <div className="z-60 "> 
      <ToastContainer
      position = {"bottom-right"}
      autoClose={1500}
      limit={1}
      closeOnClick/>

      </div>


      
    </div>
  )
}
