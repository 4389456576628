import React from 'react'
import SessionCard from './SessionCard'
import ExpiredSessionCard from './ExpiredSessionCard'

const MySession = ({sessions,expiredSessions}) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
      {sessions?.map((session) => {
        return (
          <SessionCard key={session.session_id} session={session}/>
        )
      })}
      {expiredSessions?.map((session) => 
          session.isExpiry && <ExpiredSessionCard key={session.id} session = {session} />
        )}

    </div>
  )
}

export default MySession