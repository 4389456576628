export const NavbarMenu = [
  {
    id: 1,
    name: "Home",
    url: "/",
  },
  {
    id: 2,
    name: "Courses",
    url: "/courses/101",
  },

  {
    id: 3,
    name: "Session",
    url: "/session",
  },
  {
    id: 4,
    name: "Products",
    url: "/products",
  },
];

export const NavbarMenuMobile = [
  {
    id: 1,
    name: "My Session",
    url: "/my-sessions",
  },
  {
    id: 2,
    name: "My Courses",
    url: "/my-courses",
  },
  {
    id: 3,
    name: "My Resources",
    url: "/my-resources",
  },
];
