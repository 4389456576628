import React, { useState, useEffect } from "react";
import {
  Rocket,
  Briefcase,
  Calendar,
  Clock,
  BookOpen,
  FileText,
  Mail,
  User,
  Users,
  Award,
  Target,
  TrendingUp,
  FileCheck,
  Presentation,
  CalendarDays,
  HelpCircle,
  ChevronDown,
  ChevronUp,
  Phone,
  Bookmark,
  PenLine,
} from "lucide-react";

import ConsultingHero from "@/components/ConsultingComponents/ConsultingHero";
import AboutProgram from "@/components/ConsultingComponents/AboutProgram";
import ApplicationForm from "@/components/ConsultingComponents/ApplicationForm";
import Curriculum from "@/components/ConsultingComponents/Curriculum";
import Instructors from "@/components/ConsultingComponents/Instructors";
import FAQs from "@/components/ConsultingComponents/FAQs";

// Main Page Component
const ConsultingProgramPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [details, setDetails] = useState({
    price: 1000,
    canApply: true,
    seats_left: 30,
  });

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="min-h-screen bg-black text-white font-inter px-4 md:px-0">
      {/* Hero Section */}
      <ConsultingHero details={details} ApplicationForm={ApplicationForm} />
      {/* About the Program Section */}
      <AboutProgram />
      {/* Meet Your Instructors Section */}
      <Instructors />
      {/* Program Curriculum Section */}
      <Curriculum />
      {/* FAQs Section */}
      <FAQs />
    </div>
  );
};

export default ConsultingProgramPage;
