import React, { useEffect, useState } from 'react';
import {
  Upload,
  ArrowRight,
  FileText,
  Target,
  Clock,
  Zap,
} from 'lucide-react';
import FileUploadForm from '@/components/Forms/FileUploadForm';


const Card = ({ children, className }) => (
  <div
    className={`bg-gray-900/50 backdrop-blur-xl border border-gray-800/60 rounded-xl hover:border-violet-500/30 transition-all duration-300 ${className}`}
  >
    {children}
  </div>
);

const ResumeReviewPage = () => {
  const [file, setFile] = useState(null);
  const stats = [
    { value: "500+", label: "Templates Delivered" },
    { value: "4.9", label: "Average Rating" },
    { value: "2 Days", label: "Delivery Time" },
  ];

  const features = [
    {
      icon: FileText,
      title: 'ATS-Optimized Review',
      description:
        'Get your resume optimized for Applicant Tracking Systems with our expert analysis.',
      color: 'from-blue-400 to-blue-600',
    },
    {
      icon: Target,
      title: 'Industry-Specific Feedback',
      description:
        'Receive tailored suggestions based on your target industry and role.',
      color: 'from-purple-400 to-purple-600',
    },
    {
      icon: Clock,
      title: '24-Hour Turnaround',
      description:
        'Quick delivery without compromising on quality and attention to detail.',
      color: 'from-pink-400 to-pink-600',
    },
    {
      icon: Zap,
      title: 'Priority Support',
      description:
        'Direct access to our experts for follow-up questions and revisions.',
      color: 'from-orange-400 to-orange-600',
    },
  ];

   const [isVisible, setIsVisible] = useState(false);
   useEffect(() => {
       setIsVisible(true);
     }
     , []);

  return (
    <div className="min-h-screen md:-mt-16 bg-gray-950 text-white">
      {/* Hero Section */}
      <div className="fixed inset-0 -z-10 bg-[linear-gradient(to_bottom,rgba(0,0,0,0)_0%,rgba(0,0,0,0.8)_100%)]">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSA2MCAwIEwgMCAwIDAgNjAiIGZpbGw9Im5vbmUiIHN0cm9rZT0icmdiYSg5OSwgMTAyLCAyNDEsIDAuMSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvcGF0dGVybj48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSIvPjwvc3ZnPg==')] opacity-20"></div>
      </div>
      <div
        className={`relative group transition-all duration-1000 delay-300 ${
          isVisible
            ? "opacity-100 translate-y-0"
            : "opacity-0 translate-y-10"
        }`}
      > 
       

        <div className="px-4 sm:px-6 lg:px-8  grid lg:grid-cols-2 gap-12 max-w-7xl mx-auto pt-24 lg:pt-40">
         

        <div className="space-y-8">
          <div className="inline-flex items-center gap-2 text-purple-400 text-sm">
            <div className="w-2 h-2 rounded-full bg-purple-400 animate-pulse"></div>
            Premium Offer - Limited Time!
          </div>

          <h1 className="text-4xl lg:text-6xl font-bold leading-tight">
          Resume 
            <br />
            <span className="bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">
            Review Service
            </span>
           
          </h1>

          <p className="text-gray-400 text-lg leading-relaxed">
          Your resume is your first impression. Don’t let it be the reason you miss out on that dream opportunity! Whether you’re unsure about the layout, wording, or if it’s even the right fit for the job you're eyeing – we’ve got you covered.
          </p>

          <div className="space-y-8">
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold leading-tight">
                Our Professional
                <br />
                <span className="bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent">
                  Review Process
                </span>
              </h2>

              <div className="space-y-6">
                {[
                  {
                    title: 'Submit Your Resume',
                    description:
                      'Upload your current resume and share your career goals.',
                  },
                  {
                    title: 'Expert Review',
                    description:
                      'Our industry experts analyze every aspect of your resume.',
                  },
                  {
                    title: 'Detailed Feedback',
                    description:
                      'Receive comprehensive feedback and suggestions.',
                  },
                  {
                    title: 'Implementation Support',
                    description:
                      'Get guidance on implementing the recommended changes.',
                  },
                ].map((step, index) => (
                  <div key={index} className="flex gap-4 items-start">
                    <div className="w-8 h-8 rounded-full bg-purple-400/20 flex items-center justify-center flex-shrink-0">
                      <span className="text-purple-400 font-semibold">
                        {index + 1}
                      </span>
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg mb-1">
                        {step.title}
                      </h3>
                      <p className="text-gray-400">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

         

{/* <div className="grid grid-cols-3 gap-6">
                {stats.map((stat, index) => (
                  <Card key={index} className="p-4 text-center group">
                    <div className="text-2xl font-bold bg-gradient-to-r from-violet-400 to-indigo-400 bg-clip-text text-transparent group-hover:scale-110 transition-transform">
                      {stat.value}
                    </div>
                    <div className="text-sm text-gray-400">{stat.label}</div>
                  </Card>
                ))}
              </div> */}
        </div>
          <FileUploadForm/>
        </div>
      </div>

      

      {/* Premium Features Section */}
      <div className="py-16 sm:py-32 ">
        <div className="max-w-7xl mx-auto px-4 sm:px-8 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold text-white mb-12">
            Premium Features
          </h2>
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className={`p-8 rounded-xl bg-gradient-to-r ${feature.color} text-white shadow-lg`}
              >
                <feature.icon className="w-12 h-12 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-sm">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeReviewPage;