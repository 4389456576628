import React from 'react'
import DateTimePickerDialog from '../CallSection/DateTimePickerDialog'

const ExpiredSessionCard = ({session}) => {
  return (
    <div className="w-full max-w-md bg-gray-800 text-white shadow-lg rounded-lg overflow-hidden h-fit">
      <div className="px-6 py-4">
        <div className="flex items-center justify-between mb-5">
          <div className="flex items-center">
            <div className="w-12 h-12 rounded-full overflow-hidden mr-4">
              <img src={session?.author_image} alt="Creator" className='w-full h-full object-cover rounded-full' />
            </div>
            <div>
              <h3 className="text-lg font-semibold">{session.title}</h3>
              <p className="text-sm text-gray-400">with {session?.author}</p>
            </div>
          </div>
          
        </div>
        
      </div>
      <div className="px-6 py-4 bg-gray-700 flex justify-between items-center">
        
       
        <DateTimePickerDialog title="Rebook Session" buttonText="Rebook"  isReschedule={false}  dynamicPricing={false} id={session?.id} isRebook={true} >
          
        <button 
          
          className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
        >
          Rebook
        </button>
        </DateTimePickerDialog>
      </div>
    </div>
  )
}

export default ExpiredSessionCard