// ResourceCard.jsx
import React from 'react';
import { Calendar, Link } from 'lucide-react';

const ResourceCard = ({ product }) => {
  return (
    <div className="w-full relative group">
      {/* Offset background effects */}
      <div className="absolute -right-2 -bottom-2 w-full h-full bg-purple-600/20 rounded-xl transition-all duration-300 group-hover:-translate-x-2 group-hover:translate-y-2" />
      <div className="absolute -left-2 -top-2 w-full h-full bg-indigo-600/20 rounded-xl transition-all duration-300 group-hover:translate-x-2 group-hover:-translate-y-2" />

      {/* Main card */}
      <div className="relative bg-slate-900 rounded-xl p-6 border border-slate-800 backdrop-blur-xl backdrop-filter">
        {/* Gradient overlay */}
        <div className="absolute inset-0 rounded-xl bg-gradient-to-b from-white/5 to-transparent opacity-50 group-hover:opacity-70 transition-opacity duration-300 pointer-events-none" />

        <div className="flex items-start gap-4 mb-4">
          {/* Author image with gradient border */}
          {/* <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-tr from-purple-500 via-indigo-500 to-purple-500 rounded-lg animate-gradient" />
            <img
              src={product?.authorImage}
              alt={product?.authorName}
              className="relative w-16 h-16 rounded-lg object-cover border-2 border-slate-800"
            />
          </div> */}

          <div>
            <h3 className="text-xl font-semibold text-white mb-1 group-hover:text-purple-200 transition-colors duration-300">
              {product?.title}
            </h3>
            <div className="flex items-center gap-2 text-slate-400">
              <Calendar size={16} className="transition-colors duration-300 group-hover:text-purple-400" />
              <span className="text-sm">
  Purchased on {new Date(product?.purchased_date)
    .toLocaleDateString('en-GB')
    .replace(/\//g, '-')}
</span>


            </div>
          </div>
        </div>

        <div className="flex items-center gap-4">
          {/* <div className="text-sm text-slate-400">
            By <span className="text-white transition-colors duration-300 group-hover:text-purple-200">{product?.authorName}</span>
          </div> */}
          <a
            href={product?.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-500 hover:to-purple-500 rounded-lg text-white ml-auto transition-all duration-300 group-hover:shadow-lg group-hover:shadow-purple-500/20 hover:scale-105"
          >
            View Resource
            <Link size={16} className="group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResourceCard;