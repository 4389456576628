
// import React, { useState, useEffect, useCallback } from "react";
// import {
//   Home,
//   FileText,
//   GraduationCap,
//   BookOpen,
//   User
// } from "lucide-react";

// const GlassmorphicNav = ({
//   initialTab = "home",
//   className = "",
//   glassOpacity = 95,
//   onTabChange = () => {},
//   customColors = {},
//   size = "default" // 'small' | 'default' | 'large'
// }) => {
//   const [activeTab, setActiveTab] = useState(initialTab);
//   const [hoveredTab, setHoveredTab] = useState(null);
//   const [focusVisible, setFocusVisible] = useState(false);

//   const navItems = [
//     {
//       id: "resume",
//       icon: FileText,
//       label: "Resume",
//       color: customColors.resume || "#60A5FA",
//       description: "View your resume",
//       shortcut: "Alt + 1"
//     },
//     {
//       id: "session",
//       icon: BookOpen,
//       label: "Session",
//       color: customColors.session || "#A78BFA",
//       description: "Current session details",
//       shortcut: "Alt + 2"
//     },
//     {
//       id: "home",
//       icon: Home,
//       label: "Home",
//       color: customColors.home || "#FB7185",
//       description: "Go to homepage",
//       shortcut: "Alt + 3"
//     },
//     {
//       id: "courses",
//       icon: GraduationCap,
//       label: "Courses",
//       color: customColors.courses || "#34D399",
//       description: "View your courses",
//       shortcut: "Alt + 4"
//     },
//     {
//       id: "profile",
//       icon: User,
//       label: "Profile",
//       color: customColors.profile || "#FBBF24",
//       description: "Your profile settings",
//       shortcut: "Alt + 5"
//     },
//   ];

//   const handleKeyNavigation = useCallback((e) => {
//     const currentIndex = navItems.findIndex(item => item.id === activeTab);
    
//     if (e.altKey && e.key >= "1" && e.key <= "5") {
//       e.preventDefault();
//       const newTab = navItems[parseInt(e.key) - 1].id;
//       setActiveTab(newTab);
//       onTabChange(newTab);
//     }

//     if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
//       e.preventDefault();
//       const direction = e.key === "ArrowLeft" ? -1 : 1;
//       const newIndex = (currentIndex + direction + navItems.length) % navItems.length;
//       setActiveTab(navItems[newIndex].id);
//       onTabChange(navItems[newIndex].id);
//     }
//   }, [activeTab, onTabChange]);

//   useEffect(() => {
//     window.addEventListener("keydown", handleKeyNavigation);
//     return () => window.removeEventListener("keydown", handleKeyNavigation);
//   }, [handleKeyNavigation]);

//   useEffect(() => {
//     const handleKeyDown = (e) => {
//       if (e.key === "Tab") {
//         setFocusVisible(true);
//       }
//     };
    
//     const handleMouseDown = () => {
//       setFocusVisible(false);
//     };

//     window.addEventListener("keydown", handleKeyDown);
//     window.addEventListener("mousedown", handleMouseDown);
    
//     return () => {
//       window.removeEventListener("keydown", handleKeyDown);
//       window.removeEventListener("mousedown", handleMouseDown);
//     };
//   }, []);

//   const getSizeClasses = () => {
//     switch (size) {
//       case "small":
//         return {
//           nav: "py-2",
//           icon: { default: 20, home: 24 },
//           text: "text-xs",
//           padding: "p-2"
//         };
//       case "large":
//         return {
//           nav: "py-6",
//           icon: { default: 28, home: 36 },
//           text: "text-sm",
//           padding: "p-4"
//         };
//       default:
//         return {
//           nav: "py-4",
//           icon: { default: 24, home: 32 },
//           text: "text-xs",
//           padding: "p-3"
//         };
//     }
//   };

//   const sizeClasses = getSizeClasses();

//   return (
//     <div className={`fixed bottom-0 left-0 right-0 ${className}`}>
//       <div 
//         className="absolute inset-0 backdrop-blur-2xl border-t border-gray-800 transition-all duration-300"
//         style={{ backgroundColor: `rgba(17, 24, 39, ${glassOpacity / 100})` }}
//       />
      
//       {/* Animated gradient line */}
//       <div className="absolute -top-px left-0 right-0 h-px bg-gradient-to-r from-transparent via-gray-700 to-transparent">
//         <div className="h-full w-full animate-pulse" />
//       </div>
      
//       <nav className="relative max-w-lg mx-auto px-4" role="navigation">
//         <ul className={`flex items-center justify-around ${sizeClasses.nav}`} role="tablist">
//           {navItems.map(({ id, icon: Icon, label, color, description, shortcut }) => (
//             <li
//               key={id}
//               className={`${id === "home" ? "-mt-8" : ""} relative group`}
//             >
//               <button
//                 onClick={() => {
//                   setActiveTab(id);
//                   onTabChange(id);
//                 }}
//                 onMouseEnter={() => setHoveredTab(id)}
//                 onMouseLeave={() => setHoveredTab(null)}
//                 onFocus={() => setHoveredTab(id)}
//                 onBlur={() => setHoveredTab(null)}
//                 className={`
//                   flex flex-col items-center relative outline-none
//                   ${focusVisible ? "focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-900 focus-visible:ring-gray-700" : ""}
//                   rounded-2xl
//                 `}
//                 role="tab"
//                 aria-selected={activeTab === id}
//                 aria-label={`${description} (${shortcut})`}
//                 aria-controls={`panel-${id}`}
//                 tabIndex={activeTab === id ? 0 : -1}
//               >
//                 <div
//                   className={`relative ${sizeClasses.padding} rounded-2xl transition-all duration-300 
//                     ${id === "home" ? "bg-gray-800 hover:bg-gray-700" : "hover:bg-gray-800/50"}
//                     ${activeTab === id ? "scale-110" : "hover:scale-105"}
//                     transform-gpu`}
//                 >
//                   <Icon
//                     size={id === "home" ? sizeClasses.icon.home : sizeClasses.icon.default}
//                     className="transition-all duration-300 transform-gpu"
//                     style={{ 
//                       color: activeTab === id || hoveredTab === id ? color : '#9CA3AF',
//                       filter: activeTab === id ? `drop-shadow(0 0 8px ${color}40)` : 'none'
//                     }}
//                   />
                  
//                   {/* Animated glow effect */}
//                   <div
//                     className={`absolute inset-0 rounded-2xl transition-opacity duration-300 
//                       ${activeTab === id ? "opacity-100" : "opacity-0"}
//                       ${hoveredTab === id ? "opacity-50" : ""}`}
//                     style={{ 
//                       background: `radial-gradient(circle at center, ${color}20 0%, ${color}05 70%, transparent 100%)`,
//                       boxShadow: `0 0 20px ${color}30`
//                     }}
//                   />
//                 </div>

//                 <span
//                   className={`${sizeClasses.text} mt-1.5 transition-all duration-300 transform-gpu
//                     ${activeTab === id ? "font-medium" : "font-normal"}
//                     ${hoveredTab === id ? "scale-105" : ""}`}
//                   style={{
//                     color: activeTab === id || hoveredTab === id ? color : "#6B7280",
//                   }}
//                 >
//                   {label}
//                 </span>

//                 {/* Enhanced tooltip with keyboard shortcut */}
//                 <div
//                   className={`absolute -top-12 left-1/2 transform -translate-x-1/2 px-3 py-1.5 
//                     bg-gray-800 text-gray-100 text-xs rounded-lg opacity-0 transition-opacity duration-200
//                     pointer-events-none whitespace-nowrap group-hover:opacity-100
//                     ${hoveredTab === id ? "opacity-100" : ""}`}
//                 >
//                   <div className="flex items-center gap-2">
//                     <span>{description}</span>
//                     <span className="text-gray-400 border border-gray-600 px-1.5 py-0.5 rounded text-xs">
//                       {shortcut}
//                     </span>
//                   </div>
//                   <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-gray-800 rotate-45" />
//                 </div>
//               </button>
//             </li>
//           ))}
//         </ul>
//       </nav>
//     </div>
//   );
// };

// export default GlassmorphicNav;


// import React, { useState, useContext } from "react";
// import { Home, FileText, GraduationCap, BookOpen, User } from "lucide-react";
// import { MainContext } from "@/context/MainContext";
// import UserAvatar from "../UserAvatar/UserAvatar";
// import LoginComponent from "../LoginComponent/LoginComponent";

// const GlassmorphicNav = ({
//   initialTab = "home",
//   className = "",
//   glassOpacity = 95,
//   onTabChange = () => {},
//   customColors = {},
//   size = "default"
// }) => {
//   const [activeTab, setActiveTab] = useState(initialTab);
//   const [hoveredTab, setHoveredTab] = useState(null);
//   const { user } = useContext(MainContext);

//   const navItems = [
//     { id: "resume", icon: FileText, label: "Resume", color: customColors.resume || "#60A5FA" },
//     { id: "session", icon: BookOpen, label: "Session", color: customColors.session || "#A78BFA" },
//     { id: "home", icon: Home, label: "Home", color: customColors.home || "#FB7185" },
//     { id: "courses", icon: GraduationCap, label: "Courses", color: customColors.courses || "#34D399" },
//     { id: "profile", icon: User, label: "Profile", color: customColors.profile || "#FBBF24" }
//   ];

//   const handleTabClick = (id) => {
//     setActiveTab(id);
//     onTabChange(id);
//   };

//   const renderNavButton = (id, Icon, label, isProfile = false) => {
//     const baseButtonClasses = "flex flex-col items-center text-gray-600 w-full transition-all duration-300";
//     const buttonContent = (
//       <>
//         <Icon 
//           size={24} 
//           className={`transition-all duration-300 ${activeTab === id ? 'text-white' : ''}`}
//         />
//         <span className={`text-xs ${activeTab === id ? 'text-white' : ''}`}>{label}</span>
//       </>
//     );

//     if (isProfile && !user) {
//       return (
//         <LoginComponent>
//           <button className={baseButtonClasses}>
//             {buttonContent}
//           </button>
//         </LoginComponent>
//       );
//     }

//     return (
//       <button
//         className={baseButtonClasses}
//         onClick={() => handleTabClick(id)}
//         role="tab"
//         aria-selected={activeTab === id}
//         aria-controls={`${id}-panel`}
//       >
//         {isProfile && user ? (
//           <div className="flex flex-col items-center">
//             <UserAvatar image={user.profile_picture} />
//             <span className="text-xs mt-1">{label}</span>
//           </div>
//         ) : (
//           buttonContent
//         )}
//       </button>
//     );
//   };

//   return (
//     <div className={`fixed bottom-0 left-0 right-0 z-50 ${className}`}>
//       <div 
//         className="absolute inset-0 backdrop-blur-2xl border-t border-gray-800 transition-all duration-300"
//         style={{ backgroundColor: `rgba(17, 24, 39, ${glassOpacity / 100})` }}
//       />
      
//       <nav className="relative max-w-lg mx-auto px-4" role="navigation">
//         <ul className="flex items-center justify-around py-4" role="tablist">
//           {navItems.map(({ id, icon: Icon, label }) => (
//             <li 
//               key={id} 
//               className={`relative group ${id === "home" ? "-mt-8" : ""}`}
//             >
//               {renderNavButton(id, Icon, label, id === "profile")}
//             </li>
//           ))}
//         </ul>
//       </nav>
//     </div>
//   );
// };

// export default GlassmorphicNav;





// import React, { useState, useContext, useEffect } from "react";
// import { Home, FileText, GraduationCap, BookOpen, User } from "lucide-react";
// import { MainContext } from "@/context/MainContext";
// import UserAvatar from "../UserAvatar/UserAvatar";
// import LoginComponent from "../LoginComponent/LoginComponent";

// const GlassmorphicNav = ({
//   initialTab = "home",
//   className = "",
//   glassOpacity = 95,
//   onTabChange = () => {},
//   customColors = {},
//   size = "default"
// }) => {
//   const [activeTab, setActiveTab] = useState(initialTab);
//   const { user, isLoggedIn } = useContext(MainContext);

//   // Update active tab when initialTab prop changes
//   useEffect(() => {
//     setActiveTab(initialTab);
//   }, [initialTab]);

//   const navItems = [
//     { id: "products", icon: FileText, label: "Products", color: customColors.resume || "#60A5FA" },
//     { id: "session", icon: BookOpen, label: "Session", color: customColors.session || "#A78BFA" },
//     { id: "home", icon: Home, label: "Home", color: customColors.home || "#FB7185" },
//     { id: "courses", icon: GraduationCap, label: "Courses", color: customColors.courses || "#34D399" },
//     { id: "profile", icon: User, label: "Profile", color: customColors.profile || "#FBBF24" }
//   ];

//   const handleTabClick = (id) => {
//     setActiveTab(id);
//     onTabChange(id);
//   };

//   const handleLoginSuccess = () => {
//     // Maintain the active tab after login
//     handleTabClick('profile');
//   };

//   const renderNavButton = (id, Icon, label, isProfile = false) => {
//     const baseButtonClasses = 
//       `flex flex-col items-center text-gray-600 w-full transition-all duration-300 ${
//         activeTab === id ? 'opacity-100' : 'opacity-70 hover:opacity-100'
//       }`;

//     const buttonContent = (
//       <>
//         <Icon 
//           size={24} 
//           className={`transition-all duration-300 ${activeTab === id ? 'text-white' : ''}`}
//         />
//         <span className={`text-xs ${activeTab === id ? 'text-white' : ''}`}>{label}</span>
//       </>
//     );

//     if (isProfile) {
//       if (!isLoggedIn) {
//         return (
//           <LoginComponent onLoginSuccess={handleLoginSuccess}>
//             <button className={baseButtonClasses}>
//               {buttonContent}
//             </button>
//           </LoginComponent>
//         );
//       }
      
//       return (
//         <button
//           className={baseButtonClasses}
//           onClick={() => handleTabClick(id)}
//           role="tab"
//           aria-selected={activeTab === id}
//           aria-controls={`${id}-panel`}
//         >
//           <div className="flex flex-col items-center">
//             <UserAvatar 
//               image={user?.profile_picture} 
//               className={activeTab === id ? 'ring-2 ring-white' : ''}
//             />
//             <span className={`text-xs mt-1 ${activeTab === id ? 'text-white' : ''}`}>
//               {label}
//             </span>
//           </div>
//         </button>
//       );
//     }

//     return (
//       <button
//         className={baseButtonClasses}
//         onClick={() => handleTabClick(id)}
//         role="tab"
//         aria-selected={activeTab === id}
//         aria-controls={`${id}-panel`}
//       >
//         {buttonContent}
//       </button>
//     );
//   };

//   return (
//     <div className={`fixed bottom-0 left-0 right-0 z-50 ${className}`}>
//       <div 
//         className="absolute inset-0 backdrop-blur-2xl border-t border-gray-800 transition-all duration-300"
//         style={{ backgroundColor: `rgba(17, 24, 39, ${glassOpacity / 100})` }}
//       />
      
//       <nav className="relative max-w-lg mx-auto px-4" role="navigation">
//         <ul className="flex items-center justify-around py-4" role="tablist">
//           {navItems.map(({ id, icon: Icon, label, color }) => (
//             <li 
//               key={id} 
//               className={`relative group ${id === "home" ? "-mt-8" : ""}`}
//             >
//               {renderNavButton(id, Icon, label, id === "profile")}
//             </li>
//           ))}
//         </ul>
//       </nav>
//     </div>
//   );
// };

// export default GlassmorphicNav;


import React, { useState, useContext, useEffect } from "react";
import { Home, FileText, GraduationCap, BookOpen, User } from "lucide-react";
import { MainContext } from "@/context/MainContext";
import UserAvatar from "../UserAvatar/UserAvatar";
import LoginComponent from "../LoginComponent/LoginComponent";
import { Link } from "react-router-dom";

const GlassmorphicNav = ({
  initialTab = "home",
  className = "",
  glassOpacity = 95,
  onTabChange = () => {},
  customColors = {},
  size = "default"
}) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  const [hoveredTab, setHoveredTab] = useState(null);
  const { user, isLoggedIn } = useContext(MainContext);

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  const navItems = [
    { id: "products", icon: FileText, label: "Products", color: customColors.resume || "#60A5FA" },
    { id: "session", icon: BookOpen, label: "Session", color: customColors.session || "#A78BFA" },
    { id: "home", icon: Home, label: "Home", color: customColors.home || "#FB7185" },
    { id: "courses/101", icon: GraduationCap, label: "Courses", color: customColors.courses || "#34D399" },
    { id: "profile", icon: User, label: "Profile", color: customColors.profile || "#FBBF24" }
  ];

  const handleTabClick = (id) => {
    setActiveTab(id);
    onTabChange(id);
  };

  const handleLoginSuccess = () => {
    handleTabClick('profile');
  };

  const renderNavButton = (id, Icon, label, color, isProfile = false) => {
    const baseButtonClasses = `
      flex flex-col items-center w-full transition-all duration-300
      ${id === "home" ? "bg-gray-800 hover:bg-gray-700" : "hover:bg-gray-800/50"}
      ${activeTab === id ? "scale-110" : "hover:scale-105"}
      relative rounded-2xl px-2 pt-4 pb-2
    `;

    const buttonContent = (
      <>
        <div className="relative">
          <Icon 
            size={id === "home" ? 24 : 20}
            className="transition-all duration-300 transform-gpu"
            style={{ 
              color: activeTab === id || hoveredTab === id ? color : '#E5E7EB',
              filter: activeTab === id ? `drop-shadow(0 0 8px ${color}40)` : 'none'
            }}
          />
          
          {/* Glow effect */}
          <div
            className={`absolute inset-0 rounded-2xl transition-opacity duration-300 
              ${activeTab === id ? "opacity-100" : "opacity-0"}
              ${hoveredTab === id ? "opacity-70" : ""}`}
            style={{ 
              background: `radial-gradient(circle at center, ${color}20 0%, ${color}05 70%, transparent 100%)`,
              boxShadow: `0 0 20px ${color}40`
            }}
          />
        </div>
        <span 
          className={`text-xs mt-1.5 transition-all duration-300 transform-gpu
            ${activeTab === id ? "font-normal" : "font-normal"}
            ${hoveredTab === id ? "scale-100" : ""}`}
          style={{
            color: activeTab === id || hoveredTab === id ? color : "#E5E7EB",
          }}
        >
          {label}
        </span>
      </>
    );

    if (isProfile) {
      if (!isLoggedIn) {
        return (
          <LoginComponent onLoginSuccess={handleLoginSuccess}>
            <button 
              className={baseButtonClasses}
              onMouseEnter={() => setHoveredTab(id)}
              onMouseLeave={() => setHoveredTab(null)}
            >
              {buttonContent}
            </button>
          </LoginComponent>
        );
      }
      
      return (
        <button
          className={baseButtonClasses}
          onClick={() => handleTabClick(id)}
          onMouseEnter={() => setHoveredTab(id)}
          onMouseLeave={() => setHoveredTab(null)}
          role="tab"
          aria-selected={activeTab === id}
          aria-controls={`${id}-panel`}
        >
          <div className="flex flex-col items-center">
            <UserAvatar 
              image={user?.profile_picture} 
              className={activeTab === id ? 'ring-2 ring-white' : ''}
            />
            <span 
              className="text-xs mt-1"
              style={{
                color: activeTab === id || hoveredTab === id ? color : "#6B7280",
              }}
            >
              {label}
            </span>
          </div>
        </button>
      );
    }

    return (
      <a
        className={baseButtonClasses}
        onClick={() => handleTabClick(id)}
        onMouseEnter={() => setHoveredTab(id)}
        onMouseLeave={() => setHoveredTab(null)}
        role="tab"
        aria-selected={activeTab === id}
        aria-controls={`${id}-panel`}
        href={id === "home" ? "/" : `/${id}`} // Fix here
      >
        {buttonContent}
      </a>

    );
  };

  return (
    <div className={`fixed bottom-0 left-0 right-0 z-50 ${className}`}>
      <div 
        className="absolute inset-0 backdrop-blur-2xl border-t border-gray-800 transition-all duration-300"
        style={{ backgroundColor: `rgba(17, 24, 39, ${glassOpacity / 100})` }}
      />
      
      <nav className="relative max-w-lg mx-auto " role="navigation">
        <ul className="flex items-center justify-around " role="tablist">
          {navItems.map(({ id, icon: Icon, label, color }) => (
            <li 
              key={id} 
              className={`relative group ${id === "home" ? "-mt-8" : ""}`}
            >
              {renderNavButton(id, Icon, label, color, id === "profile")}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default GlassmorphicNav;