import React, { useState, useEffect } from "react";
import {
  Rocket,
  Briefcase,
  Calendar,
  Clock,
  BookOpen,
  FileText,
  Mail,
  User,
  Users,
  Award,
  Target,
  TrendingUp,
  FileCheck,
  Presentation,
  CalendarDays,
  HelpCircle,
  ChevronDown,
  ChevronUp,
  Phone,
  Bookmark,
  PenLine,
} from "lucide-react";

// FAQs Component
const FAQs = () => {
  const faqs = [
    {
      question: "What happens if I'm not selected?",
      answer:
        "We will be doing more such fellowship, so we'll keep you posted!",
    },
    {
      question: "How will the sessions be conducted?",
      answer: "All sessions will be live.",
    },
    {
      question: "Can I ask questions during the sessions?",
      answer:
        "Yes! All sessions are interactive, so feel free to ask questions anytime.",
    },
    {
      question: "How many students will be selected?",
      answer:
        "We keep our batches small to ensure personalized attention - typically around 10 students.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-black py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-center mb-10">
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-blue-500/10 border border-blue-500/30 backdrop-blur-sm">
            <HelpCircle className="w-4 h-4 text-blue-400" />
            <span className="text-sm font-semibold text-blue-400 tracking-wide uppercase">
              Frequently Asked Questions
            </span>
          </div>
        </div>

        <div className="space-y-6">
          <h2 className="text-center text-3xl sm:text-4xl font-bold mb-8">
            <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
              Got Questions?
            </span>
          </h2>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="group relative bg-black border border-blue-500/30 rounded-lg overflow-hidden
                          hover:border-blue-400 transition-all duration-300"
              >
                <div
                  className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-indigo-500 
                                rounded-lg blur opacity-0 group-hover:opacity-20 
                                transition duration-300"
                />

                <button
                  className="relative w-full px-6 py-4 flex items-center justify-between text-left"
                  onClick={() => toggleFAQ(index)}
                >
                  <h3 className="font-semibold text-white group-hover:text-blue-300 transition-colors">
                    {faq.question}
                  </h3>
                  {activeIndex === index ? (
                    <ChevronUp className="w-5 h-5 text-blue-400" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-blue-400" />
                  )}
                </button>

                <div
                  className={`relative overflow-hidden transition-all duration-300 ${
                    activeIndex === index ? "max-h-40" : "max-h-0"
                  }`}
                >
                  <p className="px-6 pb-4 text-gray-400 group-hover:text-gray-300">
                    {faq.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Contact Info */}
          <div className="mt-12 text-center">
            <p className="text-gray-400">
              More questions? Contact us at{" "}
              <a
                href="mailto:support@example.com"
                className="text-blue-400 hover:underline"
              >
                support@off-campus.in
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
