import React from "react";
import {
  Rocket,
  Mail,
  FileText,
  Mic,
  Lightbulb,
  Network,
  HelpCircle,
  CheckCircle,
} from "lucide-react";

const WebinarBenefits = () => {
  const benefitsData = [
    {
      icon: <Rocket className="w-5 h-5 text-indigo-400" />,
      title: "Comprehensive Career Guidance",
      description:
        "Get personalized insights from industry experts who have cracked placements at top tech companies",
    },
    {
      icon: <Network className="w-5 h-5 text-blue-500" />,
      title: "Networking Opportunities",
      description:
        "Connect with professionals and peers, expanding your professional network strategically",
    },
    {
      icon: <FileText className="w-5 h-5 text-purple-400" />,
      title: "Resource Pack",
      description:
        "Receive exclusive downloadable materials curated by industry professionals",
    },
    {
      icon: <HelpCircle className="w-5 h-5 text-emerald-400" />,
      title: "Q&A Session",
      description:
        "Direct interaction with career mentors to get your specific doubts clarified",
    },
  ];

  return (
    <div className="bg-black py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-center mb-10">
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-indigo-500/10 border border-indigo-500/30 backdrop-blur-sm">
            <div className="w-4 h-4 bg-indigo-400 rounded-full animate-pulse" />
            <span className="text-sm font-semibold text-indigo-400 tracking-wide uppercase">
              Additional Benefits
            </span>
          </div>
        </div>

        <div className="space-y-6">
          <h2 className="text-center text-3xl sm:text-4xl font-bold mb-8">
            <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
              Beyond Just a Webinar
            </span>
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            {benefitsData.map((benefit, index) => (
              <div
                key={index}
                className="group relative bg-black border border-indigo-500/30 rounded-lg p-6 
                           hover:border-indigo-400 transition-all duration-300 
                           hover:bg-gradient-to-br hover:from-indigo-900/20 hover:to-blue-900/20"
              >
                <div
                  className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 to-blue-500 
                                rounded-lg blur opacity-0 group-hover:opacity-30 
                                transition duration-300"
                />

                <div className="relative flex items-start gap-4">
                  <div className="p-3 rounded-lg bg-indigo-500/10">
                    {benefit.icon}
                  </div>

                  <div className="flex-1">
                    <h3 className="text-lg font-bold mb-2 text-white group-hover:text-indigo-300 transition-colors">
                      {benefit.title}
                    </h3>
                    <p className="text-gray-400 text-sm group-hover:text-gray-200 transition-colors">
                      {benefit.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Bonus Card */}
          <div
            className="mt-6 bg-gradient-to-r from-purple-500/20 to-blue-500/20 rounded-xl p-6 
                          backdrop-blur-xl border border-purple-500/20 
                          hover:border-purple-500/50 transition-all duration-300 
                          hover:shadow-lg hover:shadow-purple-500/20 
                          transform hover:scale-105"
          >
            <div className="flex items-center gap-4">
              <div
                className="p-3 bg-purple-500/20 rounded-xl text-purple-400 
                              hover:scale-110 transition-transform duration-300"
              >
                <Lightbulb className="w-6 h-6" />
              </div>
              <div>
                <h3
                  className="font-semibold text-lg text-purple-400 
                               hover:text-purple-300 transition-colors duration-300"
                >
                  Bonus Resources Worth ₹200
                </h3>
                <p
                  className="text-gray-400 hover:text-gray-300 
                              transition-colors duration-300"
                >
                  Get exclusive materials immediately after booking
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarBenefits;
