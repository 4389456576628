// Hero Section Component
import React, { useState, useEffect } from "react";
import {
  Rocket,
  Briefcase,
  Calendar,
  Clock,
  BookOpen,
  FileText,
  Mail,
  User,
  Users,
  Award,
  Target,
  TrendingUp,
  FileCheck,
  Presentation,
  CalendarDays,
  HelpCircle,
  ChevronDown,
  ChevronUp,
  Phone,
  Bookmark,
  PenLine,
} from "lucide-react";

// import ApplicationForm from "@/components/ConsultingComponents/ApplicationForm";

const ConsultingHero = ({ details, ApplicationForm }) => {
  return (
    <section className="relative w-full flex items-center justify-center bg-black overflow-hidden py-12 mt-6">
      {/* Background Gradients */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900/10 via-black to-black opacity-90" />
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,rgba(128,128,128,0.1),transparent_50%)]" />

      {/* Content Container */}
      <div className="relative z-10 w-full max-w-4xl mx-auto px-4 py-16 lg:py-8">
        <div className="flex flex-col items-center text-center space-y-6 lg:space-y-8">
          {/* Promo Tag */}
          <div
            className="inline-flex items-center gap-2 px-4 py-2 rounded-full 
            bg-blue-500/10 border border-blue-500/30 backdrop-blur-sm"
          >
            <div className="relative">
              <div className="absolute -inset-0.5 bg-blue-500 rounded-full animate-ping opacity-40" />
              <Briefcase className="w-4 h-4 text-blue-400 relative" />
            </div>
            <span className="text-sm font-semibold text-blue-400 tracking-wide uppercase">
              🔥 Limited Seats Available!
            </span>
          </div>

          {/* Main Headline */}
          <div className="w-full max-w-8xl">
            <h1 className="text-4xl md:text-4xl lg:text-6xl font-bold leading-tight">
              <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent whitespace-normal md:whitespace-nowrap">
                Let's Break Into Consulting!
              </span>
            </h1>
          </div>

          {/* Divider */}
          <div className="w-24 h-1 bg-gradient-to-r from-gray-600 to-gray-400 rounded-full" />

          {/* Subheadline */}
          <p className="text-lg text-gray-400 max-w-4xl">
            A 4-week power-packed fellowship designed for those aiming to break
            into consulting. Master cases, guesstimates, resumes, cold mailing,
            and referral techniques{" "}
            <span className="whitespace-nowrap">
              with those who have been there.
            </span>
          </p>

          {/* CTA */}
          <div>
            <button
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSfPZbzISgsQFZ_faTaEGyQM0jaGTWPGEKcaEOdoNZe_qwLBoA/viewform",
                  "_blank"
                )
              }
              className="px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-800 
                         text-white font-bold rounded-lg shadow-lg 
                         hover:shadow-blue-600/20 transition-all duration-300
                         hover:scale-105"
            >
              Apply Now
            </button>
          </div>
        </div>
      </div>

      {/* Bottom Gradient Overlay */}
      <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-black to-transparent" />
    </section>
  );
};

export default ConsultingHero;
