import React from 'react';
import { 
  Rocket, 
  Linkedin, 
  Mail, 
  FileText, 
  Mic, 
  Sparkles 
} from 'lucide-react';

const WebinarPointers = () => {
  const keyPointers = [
    {
      icon: <Rocket className="w-5 h-5 text-indigo-400" />,
      title: "Best roles & brands to target",
      description: "Discover hidden opportunities beyond traditional hiring channels"
    },
    {
      icon: <Linkedin className="w-5 h-5 text-blue-500" />,
      title: "LinkedIn strategies that get noticed",
      description: "Optimize your profile to attract recruiters and stand out"
    },
    {
      icon: <Mail className="w-5 h-5 text-emerald-400" />,
      title: "Cold emails that actually get replies",
      description: "Craft compelling outreach that breaks through the noise"
    },
    {
      icon: <FileText className="w-5 h-5 text-purple-400" />,
      title: "Resume hacks to stand out",
      description: "Transform your resume into a recruiter-magnet document"
    },
    {
      icon: <Mic className="w-5 h-5 text-pink-500" />,
      title: "Interview prep to ace \"Tell me about yourself\"",
      description: "Master the art of personal storytelling in interviews"
    }
  ];

  return (
    <div className="bg-black py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-center mb-10">
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-indigo-500/10 border border-indigo-500/30 backdrop-blur-sm">
            <Sparkles className="w-4 h-4 text-indigo-400 animate-pulse" />
            <span className="text-sm font-semibold text-indigo-400 tracking-wide uppercase">
              What You'll Learn
            </span>
          </div>
        </div>

        <div className="space-y-6">
          <h2 className="text-center text-3xl sm:text-4xl font-bold mb-8">
            <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
              Learn What 90% of Students 
            </span>
            <br />
            <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
              Aren't Doing!
            </span>
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            {keyPointers.map((pointer, index) => (
              <div 
                key={index}
                className="group relative bg-black border border-indigo-500/30 rounded-lg p-6 
                           hover:border-indigo-400 transition-all duration-300 
                           hover:bg-gradient-to-br hover:from-indigo-900/20 hover:to-blue-900/20"
              >
                <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 to-blue-500 
                                rounded-lg blur opacity-0 group-hover:opacity-30 
                                transition duration-300" />
                
                <div className="relative flex items-start gap-4">
                  <div className="p-3 rounded-lg bg-indigo-500/10">
                    {pointer.icon}
                  </div>
                  
                  <div className="flex-1">
                    <h3 className="text-lg font-bold mb-2 text-white group-hover:text-indigo-300 transition-colors">
                      {pointer.title}
                    </h3>
                    <p className="text-gray-400 text-sm group-hover:text-gray-200 transition-colors">
                      {pointer.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-10">
            <p className="text-xl text-gray-400 max-w-2xl mx-auto">
              <span className="text-white font-semibold">Insights + Fun</span> — A transformative session 
              that goes beyond traditional career advice.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarPointers;