// sessionData.js
const SessData = [
  {
    id: 101, // Added ID
    name: "Charchit Kurra",
    image: "charchit_author.jpg",
    rating: 4.8,
    reviews: 124,
    position: "BCG || Grant Thornton || Tata 1mg || Blinkit",
    status: "Available",
    nextSlot: "Today, 3 PM",
    company: {
      current: "Google",
      experience: "8+ years",
    },
    badges: ["Top 1%", "Best For Placements"],
    expertise: ["Product Strategy", "Design Systems", "User Research"],
    metrics: [
      { label: "Sessions", value: "1000+" },
      { label: "Students Placed", value: "200+" },
      { label: "Rating", value: "4.8" },
    ],
  },
  {
    id: 201, // Added ID
    name: "Chinmay Das",
    image: "chinmay_author.jpg",
    rating: 4.5,
    reviews: 98,
    position: "NITI Aayog || Urban Company || Grant Thornton || Zomato",
    status: "Busy",
    nextSlot: "Tomorrow, 10 AM",
    company: {
      current: "Amazon",
      experience: "6+ years",
    },
    badges: ["Mentor", "Best for Internships"],
    expertise: ["UX Design", "Wireframing", "Prototyping"],
    metrics: [
      { label: "Sessions", value: "100+" },
      { label: "Students Placed", value: "10+" },
      { label: "Rating", value: "4.5" },
    ],
  },

  
];
export { SessData };
