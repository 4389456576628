import React from 'react'
import { Dialog, DialogClose, DialogContent, DialogTrigger } from '../ui/dialog';
import DateTimePicker from './DateTimePicker';

const DateTimePickerDialog = ({children,title,buttonText,sessionDate,isReschedule,rescheduleFor,id,dynamicPricing,isRebook}) => {
  return (
    <Dialog>
          <DialogTrigger>
            {children}
          </DialogTrigger>
          <DialogContent className="border-none bg-inherit/0 " closeButton = "right-7 top-7 text-white">
           
             
              <DateTimePicker title={title} buttonText={buttonText} sessionDate={sessionDate} isReschedule={isReschedule} rescheduleFor={rescheduleFor} dynamicPricing={dynamicPricing} id={id} isRebook={isRebook}/>
          
          </DialogContent>
        </Dialog>
  )
}

export default DateTimePickerDialog