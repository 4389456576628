import React, { useEffect } from 'react'
import CountUp from 'react-countup'
import FlickeringGrid from '../ui/flickering-grid'
import { MagicCard } from '../ui/magic-card'
import { FaStar } from 'react-icons/fa'
import { initFlowbite } from 'flowbite'
import { CustomTooltip } from '../custom-tooltip'

const NumberCounter = () => {
  useEffect(() => {
    initFlowbite()
  }, [])
  return (
    <div className=' text-white py-12 relative z-10 container'>
     
      <div className=' grid grid-cols-2 md:grid-cols-4 md:gap-8 gap-4'>
        {/* <FlickeringGrid
          className=" absolute inset-0 z-0 size-full"
          squareSize={4}
          gridGap={6}
          color="#6B7280"
          maxOpacity={0.5}
          flickerChance={0.1}
        /> */}

<MagicCard className='numbers bg-gradient-to-r from-blue-300 via-indigo-300 to-purple-300 p-6 h-full w-full  bg-opacity-50'>
  <p className='text-3xl font-[700] text-black'>
    <CountUp start={0} end={250} duration={3} suffix='+' enableScrollSpy={true} scrollSpyOnce={true} />
  </p>
  <p className='whitespace-nowrap text-black'>Students Placed</p>
</MagicCard>


<MagicCard className='numbers bg-gradient-to-r from-green-200 via-teal-300 to-blue-400 p-6' gradientSize={200} gradientOpacity={0.5}>
  <p className='text-3xl font-[700] text-black'>
    <CountUp end={1000} separator=',' suffix='+' duration={3} enableScrollSpy={true} scrollSpyOnce={true} />
  </p>
  <p className='text-black'>Bookings</p>
</MagicCard>

<MagicCard className='numbers bg-gradient-to-r from-yellow-200 via-orange-300 to-red-400 p-6' gradientSize={200} gradientOpacity={0.5}>
  <p className='text-3xl font-[700] flex gap-2 text-black'>
    <FaStar className='text-yellow-100'/>
    <CountUp start={0} end={4} duration={3} enableScrollSpy={true} scrollSpyOnce={true} />.5
  </p>
</MagicCard>

<MagicCard className='numbers bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 p-6' gradientSize={200} gradientOpacity={0.5}>
  <p className='text-3xl font-[700] text-black'>
    <CountUp end={30000} suffix='+' duration={3} enableScrollSpy={true} scrollSpyOnce={true} separator=',' />
  </p>
  <p className='text-black'>Community</p>
</MagicCard>


        
      </div>
      <div className="flex items-center justify-center dark:bg-zinc-950">
        <div className="p-6 max-w-sm mx-auto rounded-xl shadow-md flex items-center space-x-4 dark:bg-zinc-950">
          <CustomTooltip content="Our numbers are so good, they had to make the jump from Topmate to here!" />
        </div>
      </div>
    </div>
  )
}

export default NumberCounter
