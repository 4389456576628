import React from "react";
import { X, Check, AlertTriangle } from "lucide-react";

const WebinarRules = () => {
  const positiveRules = [
    {
      icon: <Check className="w-5 h-5 text-emerald-400" />,
      text: "Session link shared one day before",
      type: "positive",
    },
    {
      icon: <Check className="w-5 h-5 text-emerald-400" />,
      text: "Pay once, learn forever",
      type: "positive",
    },
  ];

  const negativeRules = [
    {
      icon: <X className="w-5 h-5 text-red-400" />,
      text: "No recordings (so take notes!)",
      type: "negative",
    },
    {
      icon: <X className="w-5 h-5 text-red-400" />,
      text: "No refunds (choose wisely)",
      type: "negative",
    },
  ];

  return (
    <div className="bg-black py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-center mb-10">
          <div
            className="inline-flex items-center gap-2 px-4 py-2 rounded-full 
                          bg-indigo-500/10 border border-indigo-500/30 backdrop-blur-sm"
          >
            <AlertTriangle className="w-4 h-4 text-red-400 animate-pulse" />
            <span className="text-sm font-semibold text-indigo-400 tracking-wide uppercase">
              Quick Rules
            </span>
          </div>
        </div>

        <div className="space-y-6">
          <h2 className="text-center text-3xl sm:text-4xl font-bold mb-8">
            <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
              Important Things to Know
            </span>
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-6">
              <h3 className="text-xl font-semibold text-red-400 text-center">
                What to Avoid
              </h3>
              {negativeRules.map((rule, index) => (
                <div
                  key={index}
                  className="group relative bg-black border border-red-500/30 rounded-lg p-6 
                             hover:border-red-400 transition-all duration-300 
                             hover:bg-gradient-to-br hover:from-red-900/20 hover:to-red-900/20"
                >
                  <div
                    className="absolute -inset-0.5 bg-gradient-to-r from-red-500 to-red-700 
                                  rounded-lg blur opacity-0 group-hover:opacity-30 
                                  transition duration-300"
                  />

                  <div className="relative flex items-center gap-4">
                    <div className="p-3 rounded-lg bg-red-500/10">
                      {rule.icon}
                    </div>

                    <p className="text-gray-400 group-hover:text-gray-200 transition-colors">
                      {rule.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="space-y-6">
              <h3 className="text-xl font-semibold text-emerald-400 text-center">
                What to Expect
              </h3>
              {positiveRules.map((rule, index) => (
                <div
                  key={index}
                  className="group relative bg-black border border-emerald-500/30 rounded-lg p-6 
                             hover:border-emerald-400 transition-all duration-300 
                             hover:bg-gradient-to-br hover:from-emerald-900/20 hover:to-emerald-900/20"
                >
                  <div
                    className="absolute -inset-0.5 bg-gradient-to-r from-emerald-500 to-emerald-700 
                                  rounded-lg blur opacity-0 group-hover:opacity-30 
                                  transition duration-300"
                  />

                  <div className="relative flex items-center gap-4">
                    <div className="p-3 rounded-lg bg-emerald-500/10">
                      {rule.icon}
                    </div>

                    <p className="text-gray-400 group-hover:text-gray-200 transition-colors">
                      {rule.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarRules;
