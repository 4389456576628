import axios from 'axios';

// export const baseURL = "http://localhost:3001";
// export const baseURL = "https://off-campus-with-backend.onrender.com";
export const baseURL = "https://off-campus.in";

// export const url = "http://localhost:5173";
// export const url = "https://off-campus-with-backend.onrender.com"
export const url = "https://off-campus.in";

const api = axios.create({
    // baseURL: "http://localhost:3001/api",
    // baseURL: "https://off-campus.onrender.com/api",
    baseURL : "https://off-campus.in/api",
    // withCredentials: true,
});

export const googleAuth = (code) => api.post(`auth/google?code=${code}`,{code});


