import React, { useEffect, useState } from 'react'
// import Cards from './Cards'
import ResourceCard from './ResourceCard'

const ResourceComponent = ({data}) => {
  const [isVisible, setIsVisible] = useState(false);
 
  
    useEffect(() => {
      setIsVisible(true);
    }, []);
//   return (
//     <div className='w-full max-w-screen-lg mx-auto flex items-center justify-center'>
//     <div className={` mt-10  gap-10 flex flex-col  transition-all duration-1000 ${
//                 isVisible
//                   ? "opacity-100 translate-y-0"
//                   : "opacity-0 translate-y-10"
//               }`}>
      
//       {data.map((product) => (
//         <ResourceCard key={product.id} product={product} />
//       ))}
        
//     </div>
//     </div>
//   )

return (
    <div className="w-full px-4 sm:px-6 md:px-8">
      <div className={`max-w-lg sm:max-w-md md:max-w-lg mx-auto mt-10 space-y-12 sm:space-y-12 transition-all duration-1000 ${
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}>
        {data.map((product) => (
          <ResourceCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
}

export default ResourceComponent