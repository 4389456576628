// import { Award, Linkedin } from "lucide-react";

// // Instructors Component
// const Instructors = () => {
//   const instructors = [
//     {
//       name: "Charchit Kurra",
//       linkedinUrl: "https://www.linkedin.com/in/charchit-kurra-3698b821a/",
//       description: "BCG || Grant Thornton || Tata 1mg || Blinkit",
//       image: charchitimg,
//     },
//     {
//       name: "Harsh Aggarwal",
//       linkedinUrl: "https://www.linkedin.com/in/harsh-aggarwal1/",
//       description: "Zomato || Grant Thornton, KPMG, EY || 5x Case Comp.",
//       image: harshimg,
//     },
//   ];

//   return (
//     <div className="bg-black py-16 px-4">
//       <div className="max-w-5xl mx-auto">
//         <div className="flex items-center justify-center mb-12">
//           {/* <div className="inline-flex items-center gap-2 px-5 py-2 rounded-full bg-blue-500/10 border border-blue-500/20 backdrop-blur-sm">
//             <Award className="w-4 h-4 text-blue-400" />
//             <span className="text-sm font-semibold text-blue-400 tracking-wide uppercase">
//               Meet Your Instructors
//             </span>
//           </div> */}
//         </div>

//         <h2 className="text-center text-3xl sm:text-4xl font-bold mb-16">
//           <span className="bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
//             Meet Your Instructors
//           </span>
//         </h2>

//         <div className="grid md:grid-cols-2 gap-16 justify-items-center">
//           {instructors.map((instructor, index) => (
//             <div key={index} className="group relative w-full max-w-xs">
//               {/* Instructor Container */}
//               <div className="flex flex-col items-center text-center">
//                 {/* Image with hover effect */}
//                 <div className="relative mb-6 transition-all duration-300 group-hover:transform group-hover:scale-105">
//                   <div className="w-40 h-40 rounded-full overflow-hidden border-2 border-blue-400/50 p-1">
//                     <img
//                       src={instructor.image || "/placeholder.svg"}
//                       alt={instructor.name}
//                       className="w-full h-full object-cover rounded-full"
//                     />
//                   </div>

//                   {/* Glow effect on hover */}
//                   <div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-full blur-md opacity-0 group-hover:opacity-30 transition-opacity duration-300 -z-10"></div>
//                 </div>

//                 {/* Name */}
//                 <h3 className="text-2xl font-bold mb-2 text-white group-hover:text-blue-300 transition-colors duration-300">
//                   {instructor.name}
//                 </h3>

//                 {/* LinkedIn Icon */}
//                 <a
//                   href={instructor.linkedinUrl}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-blue-500/10 mb-4 hover:bg-blue-500/30 transition-colors duration-300"
//                 >
//                   <Linkedin className="w-5 h-5 text-blue-400" />
//                 </a>

//                 {/* Description - Hidden by default, appears on hover */}
//                 <div className="overflow-hidden transition-all duration-500 ease-in-out max-h-0 opacity-0 group-hover:max-h-36 group-hover:opacity-100 group-hover:mt-3">
//                   <p className=" text-gray-500 group-hover:text-blue-100 transition-colors px-4 py-3 bg-blue-900/20 backdrop-blur-sm rounded-xl border border-blue-500/20 leading-relaxed line-clamp-3">
//                     {instructor.description}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Instructors;

import { Award, Linkedin } from "lucide-react";
import charchitimg from "../../../public/charchit_author.jpg";
import harshimg from "../../../public/1735959917985.jpeg";

// Instructors Component
const Instructors = () => {
  const instructors = [
    {
      name: "Charchit Kurra",
      linkedinUrl: "https://www.linkedin.com/in/charchit-kurra-3698b821a/",
      description: "BCG || Grant Thornton || Tata 1mg || Blinkit",
      image: charchitimg,
    },
    {
      name: "Harsh Aggarwal",
      linkedinUrl: "https://www.linkedin.com/in/harsh-aggarwal1/",
      description: "Zomato || Grant Thornton, KPMG, EY || 5x Case Comp.",
      image: harshimg,
    },
  ];

  return (
    <div className="bg-black py-16 px-4">
      <div className="max-w-5xl mx-auto">
        <div className="flex items-center justify-center mb-12">
          {/* <div className="inline-flex items-center gap-2 px-5 py-2 rounded-full bg-blue-500/10 border border-blue-500/20 backdrop-blur-sm">
            <Award className="w-4 h-4 text-blue-400" />
            <span className="text-sm font-semibold text-blue-400 tracking-wide uppercase">
              Meet Your Instructors
            </span>
          </div> */}
        </div>

        <h2 className="text-center text-3xl sm:text-4xl font-bold mb-16">
          <span className="bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Meet Your Instructors
          </span>
        </h2>

        <div className="grid md:grid-cols-2 gap-8 md:gap-16 justify-items-center">
          {instructors.map((instructor, index) => (
            <div key={index} className="group relative w-full max-w-xs">
              {/* Card-like container for mobile only */}
              <div
                className="
                bg-gradient-to-br from-blue-900/10 to-indigo-900/10 
                border border-blue-500/30 rounded-xl p-6
                md:bg-transparent md:border-0 md:p-0
                transition-all duration-300
              "
              >
                {/* Instructor Container */}
                <div className="flex flex-col items-center text-center">
                  {/* Image with hover effect on desktop */}
                  <div className="relative mb-6 transition-all duration-300 md:group-hover:transform md:group-hover:scale-105">
                    <div className="w-32 sm:w-40 h-32 sm:h-40 rounded-full overflow-hidden border-2 border-blue-400/50 p-1">
                      <img
                        src={instructor.image || "/placeholder.svg"}
                        alt={instructor.name}
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>

                    {/* Glow effect on hover (desktop only) */}
                    <div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-full blur-md opacity-0 md:group-hover:opacity-30 transition-opacity duration-300 -z-10"></div>
                  </div>

                  {/* Name */}
                  <h3 className="text-xl sm:text-2xl font-bold mb-2 text-white md:group-hover:text-blue-300 transition-colors duration-300">
                    {instructor.name}
                  </h3>

                  {/* LinkedIn Icon */}
                  <a
                    href={instructor.linkedinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-blue-500/10 mb-4 hover:bg-blue-500/30 transition-colors duration-300"
                  >
                    <Linkedin className="w-5 h-5 text-blue-400" />
                  </a>

                  {/* Description - Visible by default on mobile, hidden on desktop until hover */}
                  <div
                    className="
                    overflow-hidden transition-all duration-500 ease-in-out 
                    max-h-full opacity-100 mt-2
                    md:max-h-0 md:opacity-0 md:mt-0
                    md:group-hover:max-h-24 md:group-hover:opacity-100 md:group-hover:mt-2
                  "
                  >
                    <p
                      className="
                      text-gray-300 transition-colors
                      md:px-4 md:py-3 md:bg-blue-900/20 md:backdrop-blur-sm md:rounded-xl md:border md:border-blue-500/20
                      md:text-gray-400 md:group-hover:text-blue-100
                    "
                    >
                      {instructor.description}
                    </p>
                  </div>
                </div>
              </div>

              {/* Card hover effect for mobile */}
              <div
                className="
    absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-indigo-500 
    rounded-xl blur opacity-10 hover:opacity-20
    md:hidden
    pointer-events-none
    transition duration-300
  "
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Instructors;
