import { MainContext } from "@/context/MainContext";
import { baseURL } from "@/utility/api";
import { load } from "@cashfreepayments/cashfree-js";
import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";



import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// const customToast = createToast({
//     duration: 5000,
//     theme: 'dark',
//     className: 'bg-white p-3 rounded-md',
//     clickClosable: true,
//     position: 'bottom-right',
//     maxVisibleToasts: 3,
//     render: (message) => <b className="my-toast">{message}</b>,
//   });


function Checkout({info,file,title,className = "flex-1 ml-2 py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"}) {
    const {error,setError} = useContext(MainContext)
    
    let cashfree;
    var initializeSDK = async function () {          
        cashfree = await load({
            mode: "production", // "production" or "sandbox"
        });
    }
    initializeSDK();
    const navigate = useNavigate();
    const doPayment = async () => {
        
        if(info.name==="" || info.email==="" || info.phone_number===""){
            toast.error('Please fill all the fields');
            return;
        }
        if(info.phone_number.length!==10){
            toast.error('Please enter a valid phone number');
            return;
        }
        const response = await axios.post(`${baseURL}/payment/create-order`,{
            ...info
        }, {
            headers: { 
                'Content-Type': 'application/json'
            }
        });  
        
        if(response.data.message){
            toast.error(response.data.message);
            return;
        }
        const sessionId=response.data.sessionId;
        const orderId=response.data.orderId;
        let checkoutOptions = {
            paymentSessionId: sessionId,
            redirectTarget: "_modal",
        };
        cashfree.checkout(checkoutOptions).then((result) => {
            if(result.error){
                // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
                console.log("User has closed the popup or there is some payment error, Check for Payment Status");
                console.log(result.error);
            }
            if(result.redirect){
                // This will be true when the payment redirection page couldnt be opened in the same window
                // This is an exceptional case only when the page is opened inside an inAppBrowser
                // In this case the customer will be redirected to return url once payment is completed
                console.log("Payment will be redirected");
            }
            if(result.paymentDetails){
                // This will be called whenever the payment is completed irrespective of transaction status
               
                navigate(`/confirmation-page/${orderId}`);
                console.log("Payment has been completed, Check for Payment Status");
                console.log(result.paymentDetails.paymentMessage);
            }
        });
    };

    return (
        <div class="row">
            
            <button 
        type="submit" 
        className={className}
        onClick={doPayment}
      > 
        {title}
      </button>
      <div className="z-60"> 
      <ToastContainer
      position = {"bottom-right"}
      autoClose={5000}
limit={1}
closeOnClick/>

      </div>
    
        </div>
    );
}
export default Checkout;