// import React, { useContext } from "react";
// import { NavbarMenu, NavbarMenuMobile } from "../../mock_data/data";
// import { MdMenu } from "react-icons/md";
// import { motion } from "framer-motion";
// import Hamburger from "./Hamburger";
// import { FaHamburger } from "react-icons/fa";
// import LoginButton from "../LoginButton/LoginButton";
// import { useLocation } from "react-router-dom";
// import { MainContext } from "@/context/MainContext";
// import ResumeMakerBanner from "../Banner/ResumeMakerBanner";

// const Navbar = ({ isOpen, handleSubMenu }) => {
//   const location = useLocation();
//   const {isLoggedIn} = useContext(MainContext)
//   return (
//     <>
   
//       <motion.nav
//         initial={{ y: -50 }}
//         animate={{ y: 0 }}
//         transition={{ duration: 0.5, delay: 0.1 }}
//         className="  fixed top-0 left-0 right-0 z-40 shadow-md backdrop-blur-lg bg-opacity-80"
//       >
         
//         <div className="container  mx-auto flex items-center justify-between px-4 py-3 bg-transparent">
//           {/* Logo Section */}
//           <a href= "/"className="flex items-center gap-2 text-2xl text-primary  font-[800] bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-500 bg-clip-text text-transparent  ">
//             <img
//               src="/logo.png"
//               alt="Logo"
//               className="w-[100px] lg:w-[120px]"
//             />
//             {/* OFF-CAMPUS */}
//           </a>

//           {/* Menu Section */}
//           <div className="hidden lg:block z-50 w-fit">
//             <ul className="flex items-center  justify-center gap-4 bg-black/70 rounded-full py-2 px-8 shadow-sm">
//               {NavbarMenu.map((item) => (
//                 <li key={item.id}>
//                   <a
//                     href={item.url}
//                     className={`text-sm xl:text-base font-semibold text-zinc-200 px-3 py-1 hover:text-tertiary-100 transition-all duration-300 ease-in-out ${
//                       location.pathname === item.url
//                         ? "border-b-2 border-tertiary-100 px-1 pr-2"
//                         : ""
//                     }`}
//                   >
//                     {item.name}
//                   </a>
//                 </li>
//               ))}
//               <div className="flex md:hidden">
//               {isLoggedIn && NavbarMenuMobile.map((item) => (
//                 <li key={item.id}>
//                 <a
//                   href={item.url}
//                   className={`text-sm xl:text-base font-semibold text-zinc-200 px-3 py-1 hover:text-tertiary-100 transition-all duration-300 ease-in-out ${
//                     location.pathname === item.url
//                       ? "border-b-2 border-tertiary-100 px-1 pr-2"
//                       : ""
//                   }`}
//                 >
//                   {item.name}
//                 </a>
//               </li>
//             ))}
//             </div>
//             </ul>
//           </div>

//           {/* CTA Section */}
//           <div className="hidden lg:flex justify-end">
//             <LoginButton />
//           </div>

//           {/* Mobile Hamburger Menu */}
//           <div
//             className="lg:hidden flex items-center text-primary cursor-pointer"
//             onClick={handleSubMenu}
//           >
//             <FaHamburger  className="text-3xl" />
//           </div>
//         </div>
//       </motion.nav>

//       {/* Mobile Sidebar Section */}
//       <Hamburger isOpen={isOpen} handleSubMenu={handleSubMenu} />
//     </>
//   );
// };

// export default Navbar;


// import React, { useContext, useState } from "react";
// import { NavbarMenu, NavbarMenuMobile } from "../../mock_data/data";
// import { motion } from "framer-motion";
// import LoginButton from "../LoginButton/LoginButton";
// import { useLocation } from "react-router-dom";
// import { MainContext } from "@/context/MainContext";
// import GlassmorphicNav from "@/components/Navbar/TabNavbar";
// import ResumeMakerBanner from "../Banner/ResumeMakerBanner";

// const Navbar = () => {
//   const location = useLocation();
//   const { isLoggedIn } = useContext(MainContext);
  
//   // Convert your NavbarMenu items to match GlassmorphicNav format
//   const mobileNavItems = NavbarMenu.map((item, index) => ({
//     id: item.name.toLowerCase(),
//     label: item.name,
//     url: item.url,
//     shortcut: `Alt + ${index + 1}`,
//     description: `Go to ${item.name}`,
//   }));

//   const handleTabChange = (tab) => {
//     // Find the corresponding URL and navigate
//     const menuItem = mobileNavItems.find(item => item.id === tab);
//     if (menuItem) {
//       window.location.href = menuItem.url;
//     }
//   };

//   return (
//     <>
//       {/* Desktop Navbar */}
//       <motion.nav
//         initial={{ y: -50 }}
//         animate={{ y: 0 }}
//         transition={{ duration: 0.5, delay: 0.1 }}
//         className="fixed top-0 left-0 right-0 z-40 shadow-md backdrop-blur-lg bg-opacity-80"
//       >
//         <div className="container mx-auto flex items-center justify-between px-4 py-3 bg-transparent">
//           {/* Logo Section */}
//           <a href="/" className="flex items-center gap-2 text-2xl text-primary font-[800] bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-500 bg-clip-text text-transparent">
//             <img
//               src="/logo.png"
//               alt="Logo"
//               className="w-[100px] lg:w-[120px]"
//             />
//           </a>

//           {/* Desktop Menu Section */}
//           <div className="hidden lg:block z-50 w-fit">
//             <ul className="flex items-center justify-center gap-4 bg-black/70 rounded-full py-2 px-8 shadow-sm">
//               {NavbarMenu.map((item) => (
//                 <li key={item.id}>
//                   <a
//                     href={item.url}
//                     className={`text-sm xl:text-base font-semibold text-zinc-200 px-3 py-1 hover:text-tertiary-100 transition-all duration-300 ease-in-out ${
//                       location.pathname === item.url
//                         ? "border-b-2 border-tertiary-100 px-1 pr-2"
//                         : ""
//                     }`}
//                   >
//                     {item.name}
//                   </a>
//                 </li>
//               ))}
//               <div className="flex md:hidden">
//                 {isLoggedIn &&
//                   NavbarMenuMobile.map((item) => (
//                     <li key={item.id}>
//                       <a
//                         href={item.url}
//                         className={`text-sm xl:text-base font-semibold text-zinc-200 px-3 py-1 hover:text-tertiary-100 transition-all duration-300 ease-in-out ${
//                           location.pathname === item.url
//                             ? "border-b-2 border-tertiary-100 px-1 pr-2"
//                             : ""
//                         }`}
//                       >
//                         {item.name}
//                       </a>
//                     </li>
//                   ))}
//               </div>
//             </ul>
//           </div>

//           {/* Desktop CTA Section */}
//           <div className="hidden lg:flex justify-end">
//             <LoginButton />
//           </div>
//         </div>
//       </motion.nav>

//       {/* Mobile Bottom Navigation */}
//       {/* <div className="lg:hidden">
//         <GlassmorphicNav
//           initialTab={mobileNavItems.find(item => item.url === location.pathname)?.id || 'home'}
//           onTabChange={handleTabChange}
//           className="z-50"
//           glassOpacity={95}
//           customColors={{
//             home: "#60A5FA",
//             about: "#A78BFA",
//             services: "#34D399",
//             contact: "#FBBF24",
//             // Add colors for your specific menu items
//           }}
//         />
//       </div> */}

//       {/* Mobile Bottom Navigation */}
// <div className="lg:hidden">
//   <GlassmorphicNav
//     initialTab={mobileNavItems.find(item => item.url === location.pathname)?.id || 'home'}
//     onTabChange={(tab) => {
//       if (tab === 'login') {
        
//         <LoginButton />
//       } else {
//         handleTabChange(tab);
//       }
//     }}
//     className="z-50"
//     glassOpacity={95}
//     customColors={{
//       home: "#60A5FA",
//       about: "#A78BFA",
//       services: "#34D399",
//       contact: "#FBBF24",
//       profile: "#FBBF24", // Added profile color
//     }}
//   />
// </div>

//       {/* Add padding to main content for mobile */}
//       <style jsx global>{`
//         main {
//           padding-bottom: 80px; /* Adjust based on your bottom nav height */
//         }
//         @media (min-width: 1024px) {
//           main {
//             padding-bottom: 0;
//           }
//         }
//       `}</style>
//     </>
//   );
// };

// export default Navbar;


import React, { useContext, useState, useEffect } from "react";
import { NavbarMenu, NavbarMenuMobile } from "../../mock_data/data";
import { motion } from "framer-motion";
import LoginButton from "../LoginButton/LoginButton";
import { useLocation, useNavigate } from "react-router-dom";
import { MainContext } from "@/context/MainContext";
import GlassmorphicNav from "@/components/Navbar/TabNavbar";

const Navbar = ({showBottomNavBar}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(MainContext);
  const [activeTab, setActiveTab] = useState('home');

  // Effect to update active tab based on current route
  useEffect(() => {
    const path = location.pathname;
    const currentTab = NavbarMenu.find(item => item.url === path);
    if (currentTab) {
      setActiveTab(currentTab.name.toLowerCase());
    } else if (path === '/profile') {
      setActiveTab('profile');
    }
  }, [location.pathname]);

  const handleTabChange = (tab) => {

    console.log('Tab clicked:', tab);
    setActiveTab(tab);
    
    if (tab === 'profile') {
      if (isLoggedIn) {
        // navigate('/profile');

        const profileMenuItem = NavbarMenuMobile.find(
          item => item.name.toLowerCase().replace(/\s+/g, '') === tab.toLowerCase().replace(/\s+/g, '')
        );

       
    
        // if (profileMenuItem) {
        //   navigate(profileMenuItem.url);
        // }
      }
      // If not logged in, the GlassmorphicNav's LoginComponent will handle it
      return;
    }

    
    const menuItem = NavbarMenu.find(
      item => item.name.toLowerCase() === tab
    );

    
    // if (menuItem) {
    //   navigate(menuItem.url);
    // }

    // const profileMenuItem = NavbarMenuMobile.find(
    //   item => item.name.toLowerCase().replace(/\s+/g, '') === tab.toLowerCase().replace(/\s+/g, '')
    // );

    // if (profileMenuItem) {
    //   navigate(profileMenuItem.url);
    // }

    console.log('No matching route found');
    console.log('Available mobile menu items:', NavbarMenuMobile.map(item => item.name));

  };

  return (
    <>
      {/* Desktop Navbar */}
      <motion.nav
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
        className="fixed top-0 left-0 right-0 z-40 shadow-md backdrop-blur-lg bg-opacity-80"
      >
        <div className="container mx-auto flex items-center justify-between px-4 py-3 bg-transparent">
          {/* Logo Section */}
          <a href="/" className="flex items-center gap-2">
            <img
              src="/logo.png"
              alt="Logo"
              className="w-[100px] lg:w-[120px]"
            />
          </a>

          {/* Desktop Menu Section */}
          <div className="hidden lg:block z-50 w-fit">
            <ul className="flex items-center justify-center gap-4 bg-black/70 rounded-full py-2 px-8 shadow-sm">
              {NavbarMenu.map((item) => (
                <li key={item.id}>
                  <a
                    href={item.url}
                    className={`text-sm xl:text-base font-semibold text-zinc-200 px-3 py-1 hover:text-tertiary-100 transition-all duration-300 ease-in-out ${
                      location.pathname === item.url
                        ? "border-b-2 border-tertiary-100 px-1 pr-2"
                        : ""
                    }`}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Desktop CTA Section */}
          <div className="hidden lg:flex justify-end">
            <LoginButton />
          </div>
        </div>
      </motion.nav>

      {/* Mobile Bottom Navigation - Always render it */}
      {showBottomNavBar && <div className="lg:hidden fixed bottom-0 left-0 right-0 z-50">
  <GlassmorphicNav
    initialTab={activeTab}
    
    onTabChange={(tab) => {
      console.log('GlassmorphicNav tab clicked:', tab);
      handleTabChange(tab);
    }}
    glassOpacity={95}
    customColors={{
      home: "#60A5FA",
      resume: "#A78BFA",
      session: "#34D399",
      courses: "#FBBF24",
      profile: "#F87171"
    }}
  />
</div>}

      {/* Add padding to main content for mobile */}
      <style jsx global>{`
        main {
          padding-bottom: 120px;
        }
        @media (min-width: 1024px) {
          main {
            padding-bottom: 0;
          }
        }
      `}</style>
    </>
  );
};

export default Navbar;